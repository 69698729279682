@import '../styles/main.scss';

.syte-section-box {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 0 23px;
  overflow: hidden;
  display: flex;
  width: 100%;

  &-title {
    color: $color-grey-2;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    text-transform: uppercase;

    &-divider {
      border-bottom: 1px solid #d8d8d8;
    }
  }
}
