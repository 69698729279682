@import '../styles/main';

.syte-tags-input {
  background-color: $color-bg-gray-inputs !important;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  min-height: 40px;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .tag-label {
    background-color: $color-white;
    @include flexCenterAll(true);
    padding: 6px 8px;
    text-align: center;
    border-radius: 5px;
    margin-right: 8px;
    margin-top: 2px;
    margin-bottom: 2px;

    .value {
      color: $color-black-text;
      padding-right: 8px;
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .delete-icon {
      cursor: pointer;

      &:after {
        display: block;
        content: '\00d7';
      }
    }
  }
  .input {
    min-width: 30px;
    padding-right: 8px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: 40px;
  }
}

.syte-input-base.disabled {
  .tag-label {
    background-color: $color-gray_disable;

    .delete-icon {
      color: $color-main_gray;
      cursor: unset;
    }
  }
}
