@import '../styles/main';

.syte-shop-selector {
  width: 261px;
  height: 32px;
  margin-right: 24px;

  &-item {
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding: 8px;

    &-details {
      overflow: hidden;
      width: 100%;
      display: flex;
    }

    &-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-sub-title {
      color: $color-main_gray;
      margin-right: 8px;
    }

    &-splitter {
      width: 8px;
    }
  }

  &-selected-item {
    height: 32px;
    padding-left: initial;
    padding-right: initial;
    border-radius: 4px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0;
    }
  }
}

.syte-shop-selector-selected-item .syte-shop-selector-divider {
  height: 0;
  width: 0;
}

.syte-shop-selector-divider {
  height: 1px;
  width: 100%;
  border-radius: 2px;
  background-color: $color-syte-grey-60;
  margin-top: 7px;
}

.select-shop-caret-icon {
  margin-right: 8px;
  right: 0 !important;
}

.syte-list-selector {
  padding: initial;
  &-popup {
    &-wrapper {
      border-radius: 4px;
      border: 1px solid $color-syte-grey-60;
      background: $color-syte-white;

      box-shadow: 0px 6px 12px -6px rgba(0, 0, 0, 0.12), 0px 8px 24px -4px rgba(0, 0, 0, 0.08);
      margin-top: initial;
      padding-top: initial;
      .syte-list-selector-popup-search {
        padding: 8px 8px 8px 8px;
        .syte-text-box {
          .input-base-wrapper {
            background: $color-syte-grey-90;
            padding: 12px 8px;
            border-radius: 8px;
            border: initial;
            .syte-text-box-content {
              display: flex;
              width: 16px;
              height: 16px;
              padding: 0.21px 1.791px 1.79px 0.209px;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    &-search {
      padding: initial;
      margin: initial;
    }

    &-item {
      padding: 8px 0 0 0;
    }

    &-list {
      padding: 0 8px;
      & > li:last-child {
        .syte-shop-selector-divider {
          height: initial;
        }
      }
    }
  }

  &-selected-item-wrapper {
    min-height: initial;
    height: 32px;
    padding-right: initial;
    padding: 8px 16px;
    .syte-shop-selector-item {
      padding: initial;
    }
  }

  .syte-input-base {
    background: $color-syte-grey-90;
  }
}
