@import '../styles/main';

$input-border-padding: 15px;
$cell-border-padding-right: 11px;

.syte-table {
  &-header {
    @include flexCenterAll();

    min-height: 35px;
    font-size: 13px;
    font-weight: 700;
    color: $text-color;
    background-color: $color-background;

    & .th {
      height: 47px;
      text-transform: uppercase;
      color: $color-main_gray;
      font-size: 12px;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 ($cell-border-padding-right + $input-border-padding);
      &:not(.delete-column):not(.no-divider) {
        @include dashedDivider($side: right, $color: $color-grey-8);
      }
    }
    & .th:last-child {
      &::after {
        display: none;
      }
    }
  }
  &-body {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;

    & .tr {
      border-bottom: 1px solid $color-gray_disable;

      & .syte-table-editable-cell {
        width: 100%;
        &.syte-numeric-input {
          max-width: 92px;
        }

        .syte-input-base.disabled {
          border-color: transparent !important;
        }

        &.syte-drop-down.disabled {
          .syte-drop-down-input-wrapper {
            background-color: unset;
            border-color: transparent;

            &-input {
              font-weight: 400;
              font-size: 14px;
              color: $text-color-disabled;
            }

            .syte-drop-down-popup-arrow {
              opacity: 0;
            }
          }
        }
      }

      &:hover {
        .delete-row-icon {
          opacity: 1;
        }
      }
    }

    & {
      scrollbar-width: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    & .td {
      padding: 0 $cell-border-padding-right;
    }
  }

  & .tr {
    @include flexCenterAll(true);
    & .td:last-child {
      &::after {
        display: none;
      }
    }
  }

  & .td {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 70px;
    input {
      font-weight: 400;
      font-size: 14px;
    }
    &:not(.delete-column):not(.no-divider) {
      @include dashedDivider($side: right, $color: $color-grey-8);
    }
    .delete-row-icon {
      opacity: 0;
    }
  }
  .delete-column {
    padding: 0;
  }

  .delete {
    align-self: center;
  }

  &-filter-global {
    max-width: 400px;
    padding: 0px 0 7px 4px;
  }
  &-filter-column-text {
    width: 100%;
    padding: 0 20px;
  }

  .resizer {
    display: inline-block;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
  }

  &-empty-table {
    @include flexCenterAll();
    font-size: 34px;
    color: $color-grey-7;
    padding-top: 40px;
  }
  .th:not(.delete-column):not(.no-divider):after,
  .td:not(.delete-column):not(.no-divider):after {
    background-image: unset;
  }
  &-label-cell, &-link-cell {
    color: $color-grey-2;
    font-size: 14px;
    padding-left: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .syte-table-link-cell {
    color: #0073FF;
    &:hover {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}
