@import '../../../styles/main';

.syte-accounts-list {
  max-height: 70vh;

  .no-accounts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $color-dark-gray;

    .sub-title {
      font-weight: 500;
      padding-top: 1.3em;
    }
  }
}
