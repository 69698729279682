@import './variables';
@import '../../styles/mixins.scss';

@mixin inputBorder() {
  border-radius: $input-border-radius;
  border: 1px solid $input-border-color;
}

@mixin inputBorderHighlight($important) {
  border-radius: $input-border-radius;
  border: 1px solid $border-color-highlighted is-important($important);
}

@mixin inputBorderError() {
  border-radius: $input-border-radius;
  border: 1px solid $error-color !important;
}

@mixin cardBorderHover($original-border-color: 'transparent') {
  border: 1px solid #{$original-border-color};
  transition: $transition-standard;
  &:hover,
  &:focus {
    border: 1px solid $border-color-highlighted !important;
    transform: none;
  }
}

@mixin hideNumericInputSpinner() {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@mixin unstyled-list($item-spacing: 0px) {
  list-style-type: none;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;

  li:not(:first-child) {
    margin-top: $item-spacing;
  }
}
