@import '../styles/main';

.syte-modal {
  background-color: $background-color-8;
  &-backdrop {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    z-index: 2;
  }
  &-content {
    position: absolute;
    border-radius: 10px;
    box-shadow: 0px 0px 25px $background-color-8;
    background: #fff;
    opacity: 100;
    overflow: hidden;
    max-width: 90vw;
    width: 100%;
    padding: 0 !important;
    z-index: 999;
  }
}
