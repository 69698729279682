@import '../../styles/main.scss';
@import '../experiment-styles.scss';

.syte-experiment-card {
  width: 100%;
  display: grid;
  grid-template-columns: 28px 1fr;
  min-height: 72px;
  background-color: $color-background;
  border: none !important;
  transition: none !important;

  .syte-experiment-card-content {
    flex: 1;
    width: 100%;
    display: grid;
    grid-template-columns:
      minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
      minmax(100px, 1fr) 86px min-content;
    padding: 17px 24px;
  }

  .syte-experiment-card-status {
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 7px;
    border-right: $border;

    .syte-experiment-card-status-text {
      transform: rotate(-90deg);
      color: $color-white;
      text-transform: uppercase;
      display: inline-block;
    }
  }
  &-cell {
    align-items: center;
    &-label {
      color: $color-main_gray;
      text-transform: uppercase;
    }
    &-field {
      line-height: 22px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
      white-space: nowrap;
    }

    .syte-indicator-content {
      text-transform: capitalize;
    }
  }

  &-cell:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
  }

  .experiment-card-status-button {
    padding-left: 19px;
    justify-content: unset;
    .syte-button-primary-icon {
      margin-left: 0;
    }

    .syte-button-content {
      margin-left: auto;
      margin-right: auto;
    }
    width: 100%;
  }
}
