$spacing: 16px;
$textual-field-width: 25em;
$numeric-field-width: 5em;

.syte-filters-list {
  &__header {
    padding: $spacing 0;
    margin-top: 0;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - #{$spacing}));
    grid-auto-rows: min-content;
    column-gap: calc(#{$spacing} * 2);
    > div {
      width: 100%;
      .syte-input-base {
        width: $textual-field-width;
      }
      .syte-numeric-input {
        .syte-numeric-input-content {
          width: $numeric-field-width;
        }
      }
      &.syte-toggle {
        justify-content: flex-start;
        span.syte-toggle-title {
          font-weight: normal;
        }
      }
    }
    .enable-deep-tags-toggle {
      grid-area: 1/1;
      margin-bottom: $spacing;
    }
    .order-method-field {
      grid-area: 2/1;
    }
    .enable-min-buckets-toggle {
      grid-area: 1/2;
      margin-bottom: $spacing;
    }
    .min-buckets-count {
      grid-area: 2/2;
    }
  }
}
