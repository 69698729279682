@import '../../../styles/main';

.syte-variants-table {
  .variant-cell {
    &-custom-name,
    &-allocation {
      .syte-input-base.disabled {
        border: unset;
        background-color: unset;
        &:hover,
        &:focus,
        &:focus-within {
          border: unset !important;
        }
      }
    }

    &-source.production {
      color: $color-main_gray;
    }

    &-name {
      &-subtitle {
        color: $color-main_gray;
        white-space: nowrap;
      }

      &-badge {
        margin-left: 8px;

        &.production {
          min-height: 18px;
          max-width: 22px;

          .syte-badge-icon {
            margin-right: unset;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
