@import '../../styles/main.scss';

$spacing: 16px;

.syte-feature-group {
  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    background: $background-color;
    z-index: 1;
    padding: $spacing 0;

    &-icon, &-checkbox {
      margin-right: $spacing;
    }

    &-checkbox {
      margin-left: 8px;
    }

    &-title {
      color: $color-black-text;
    }
  }

  &-content {
    border-left: 1px solid $border-color;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 12px;
  }
}
