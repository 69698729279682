@import './fonts.scss';

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: Inter !important;
}

html,
body,
input,
button,
select,
textarea,
label,
h1,
h2,
h3,
h4,
h5,
p {
  font-family: Inter !important; /* semantic has specific rules like .ui.input>input for font family */
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}

a[href] {
  color: inherit;
  text-decoration: unset;
}

#root {
  background-color: #fff;
  min-height: 100%;
  min-width: 100%;
  * {
    box-sizing: border-box;
  }
}

:focus,
:focus-visible {
  outline: none;
}

li.MuiMenuItem-root.MuiMenuItem-gutters {
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiModal-root {
  z-index: 1900 !important;
}

.ui.bottom.left.popup::before {
  visibility: hidden;
}

.MuiAutocomplete-popper {
  ul {
    max-height: 300px;
  }
}
