@import '../../styles/main.scss';

.syte-variant-name-cell {
  .syte-badge {
    margin-left: 8px;
    font-size: 13px;
    &-icon {
      margin-right: unset;
      margin-left: 0;
      padding-left: 0;
    }

    &.production {
      display: inline-block;
      min-height: 18px;
      max-width: 22px;
      padding-left: 6px;
    }
  }
  .syte-variant-cell-description {
    color: $color-main_gray;
  }
}
