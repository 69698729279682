@import '../styles/main.scss';

.syte-indicator {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &-content {
    margin-left: 10px;
  }

  .syte-blue {
    background-color: $color-syte-blue;
  }
  .syte-orange {
    background-color: $color-syte-orange;
  }
  .syte-violet {
    background-color: $color-syte-violet;
  }
  .black-text {
    background-color: $color-black-text;
  }
  .main_gray {
    background-color: $color-main_gray;
  }
  .white {
    background-color: $color-white;
  }
  .experiments-color-1 {
    background-color: $color-experiments-color-1;
  }
  .experiments-color-2 {
    background-color: $color-experiments-color-2;
  }
  .experiments-color-3 {
    background-color: $color-experiments-color-3;
  }
  .experiments-color-4 {
    background-color: $color-experiments-color-4;
  }
  .experiments-color-5 {
    background-color: $color-experiments-color-5;
  }
  .experiments-color-6 {
    background-color: $color-experiments-color-6;
  }

  .syte-positive {
    background-color: $color-syte-positive;
  }
}
