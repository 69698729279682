@import './src/styles/variables';

.sku-input-container {
  display: flex;
  align-items: center;
  padding: 0 8px;

  .syte-text-box {
    width: 180px;

    .input-base-wrapper,
    .input-base-wrapper.disabled {
      background-color: $color-white;
      outline: none!important;
      border: none!important;
    }
  }

  button {
    margin-right: -8px;
  }

  .sku-error {
    color: $color-syte-negative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
