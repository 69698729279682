@import '../../../../styles/variables';

.social-post-tile {
  display: flex;
  flex-direction: column;
  width: 172px;

  .image-container {
    display: flex;
    width: 100%;
    aspect-ratio: 1;

    &.selected {
      outline: 4px solid $color-syte-primary;
      outline-offset: -4px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 5px;
    margin-top: 8px;

    .account {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $color-syte-black;
    }

    .date {
      color: $color-syte-grey-10;
    }
  }
}
