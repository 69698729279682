@import '../styles/main';

.syte-status-card {
  --status-text-color: initial;
  --status-background-color: initial;
  --status-card-border-radius: 8px;
  --status-card-border-top-radius: var(--status-card-border-radius);
  --status-card-border-bottom-radius: var(--status-card-border-radius);

  min-height: 68px;
  background-color: $color-background;
  display: grid;
  grid-template-columns: auto 1fr;
  border-top-left-radius: var(--status-card-border-top-radius);
  border-top-right-radius: var(--status-card-border-top-radius);
  border-bottom-left-radius: var(--status-card-border-bottom-radius);
  border-bottom-right-radius: var(--status-card-border-bottom-radius);
  border: none;

  .syte-status-card-status {
    height: 100%;
    width: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $border-color;
    background-color: var(--status-background-color);
    border: none;
    border-top-left-radius: var(--status-card-border-top-radius);
    border-bottom-left-radius: var(--status-card-border-bottom-radius);

    &--green {
      --status-background-color: #{$color-syte-blue-disabled};
      --status-text-color: #{$color-syte-blue-hover};
    }
    &--yellow {
      --status-background-color: #{$color-experiments-color-5};
      --status-text-color: #{$text-color-inverted};
    }
    &--red {
      --status-background-color: #{$color-experiments-color-4};
      --status-text-color: #{$text-color-inverted};
    }

    .syte-status-card-status-text {
      transform: rotate(-90deg);
      text-transform: uppercase;
      display: inline-block;
      color: var(--status-text-color);
      font-weight: 600;
    }
  }
}
