@import '../../../styles/main.scss';

.syte-user-card {
  padding: 24px 17px 12px 17px;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 14px;
    overflow: hidden;

    &-info {
      padding-left: 12px;
      max-width: 70%;

      & > div {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      & > div:first-child {
        color: $color-black-text;
      }
      & > div:last-child {
        color: $color-main_gray;
        padding-top: 3px;
      }
    }
    &-delete {
      opacity: 0;
      width: 46px;
      transition: opacity 200ms;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;

      svg {
        height: 20px;
      }
    }
  }
  &-date {
    color: $color-main_gray;
    padding: 12px 0 0;
    border-top: 1px solid $color-gray_disable;

    em {
      font-style: normal;
      margin-left: 3px;
    }
  }

  &:hover {
    .syte-user-card-content-delete {
      opacity: 1;
    }
  }
}
