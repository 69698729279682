@import '../styles/main';

.syte-collapse {
  .syte-collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .syte-collapse-trigger {
    &:not(.disabled) {
      cursor: pointer;
    }
  }

  .syte-collapse-content {
    transition: max-height 350ms ease;
    overflow: hidden;
  }

  .syte-collapse-icon {
    transform: rotate(90deg);
    transition: 0.3s transform;
  }
  &.syte-collapse-collapsed {
    .syte-collapse-icon {
      transform: rotate(270deg);
    }
  }
}
