@import '../../components-bl/styles/main';
@import '../../components-bl/styles/components-bl';

.syte-page.ranking-container {
  .syte-page-content {
    position: relative;
  }
}

.ranking-strategy-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .title {
    height: 90px;
  }
  .title h1 {
    letter-spacing: 1px;
    padding-left: 4px;
    color: $text-color;
  }

  .list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }
  
  .ranking-strategy-type {
    position: absolute;
    min-width: 250px;
    top: -25px;
    left: 290px;
  }

  .ranking-button-wrapper {
    border-top: $border;
    padding: 23px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .syte-section-box {
    flex-direction: column;
    padding: 0;
  }

  .ranking-strategy-container-products-dropdown {
    width: 250px;
    position: relative;
  }
}
