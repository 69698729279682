@import '../styles/main';

.syte-entity-card {
  .syte-card-item {
    width: 100%;
    display: grid;
    grid-template-columns: 28px 1fr 28px;
    height: 55px;
    background-color: $color-background;
    border-radius: 8px;
    border: none;
    transition: none !important;

    .syte-entity-card-content {
      flex: 1;
      width: 100%;
      // the most basic layout - will be overriden by concrete use cases:
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .syte-entity-card-status {
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 7px;
      border-right: $border;
      background-color: $color-syte-blue;

      .syte-entity-card-status-text {
        transform: rotate(-90deg);
        color: $color-white;
        text-transform: uppercase;
        display: inline-block;
      }
    }
    .syte-entity-card-cell {
      display: flex;
      align-items: center;
      &-label {
        color: $color-main_gray;
        text-transform: uppercase;
        line-height: 20px !important;
      }
      &-field {
        line-height: 22px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 5px;
        white-space: nowrap;
      }

      .syte-indicator-content {
        text-transform: capitalize;
      }
      &__action-menu-trigger {
        display: flex;
        justify-content: flex-end;
      }
    }

    &-cell:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding-left: 20px;
    }
  }
  .syte-collapse-header[aria-expanded='true'] .syte-card-item,
  .syte-card-item:hover {
    border-color: transparent;
    background-color: $color-background-hover;
  }
  &.disabled {
    .syte-card-item,
    .syte-collapse-header {
      cursor: inherit;
    }
  }
}
