@import '../styles/main';

.syte-date-time-range-picker {
  @mixin flex-row {
    display: flex !important;
    flex-direction: row;
  }

  @mixin reactDatePickerNavigation {
    content: '';
    top: 9px;
    position: absolute;
    border: solid white;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }

  @mixin inter-normal($size, $weight: 'normal') {
    font-family: Inter !important;
    font-style: normal !important;
    font-size: #{$size}px !important;
    font-weight: #{$weight} !important;
  }

  display: inline-block;

  background-color: $color-white;
  box-shadow: $box-shadow-main;
  border-radius: 8px;

  .divider-margin-bottom-10 {
    margin-bottom: 10px !important;
  }

  .tz-row {
    @include flex-row();
    align-items: center;
    justify-content: flex-start;
    .timezone-field {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 10px;
      > .timezone-field-title {
        @include inter-normal(15, 400);
        text-transform: uppercase;
        background-color: $color-white;
      }
      .syte-date-time-range-picker-time-zone-dropdown {
        width: 23em;
        margin-left: 10px;
        .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
          height: unset;
        }
      }
    }
    .action-buttons-wrapper {
      display: flex;
      align-items: center;
      padding: 10px;
      > .btn-set {
        margin-right: 10px;
        border-radius: 12px;
        line-height: 18px !important;
        height: 36px;
        @include inter-normal(13, 700);
      }
      > .btn-close {
        height: 36px;
        width: 36px;
        justify-content: center;
        align-items: center;
        padding: 0;
        border-radius: 12px;
      }
    }
  }
  .date-time-tz-picker-row {
    @include flex-row();
    justify-content: space-between;
    .start-date-wrapper {
      padding: 0 5px 0 13px;
      &--single {
        padding: 0px 12px;
      }
    }
    .ends-date-wrapper {
      padding: 0 13px 0 5px;
    }
    .syte-start-date-title,
    .syte-end-date-title {
      @include inter-normal(13, 400);
      padding: 0.5833em 0;
      background-color: unset;
      color: $color-black-text;
      text-transform: uppercase;
    }

    .react-datepicker {
      text-transform: uppercase;
      border-radius: 6px;
      @include inter-normal(13, 400);
      border: 1px solid $color-gray_disable !important;
    }

    .react-datepicker__day:focus {
      outline: none;
    }

    .react-datepicker__day {
      &--selected {
        border-radius: 1em;
        background-color: $color-syte-blue !important;
      }
      &--selected:focus {
        outline: none;
      }
      &--keyboard-selected {
        border-radius: unset;
        background-color: unset;
      }
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .react-datepicker__time-list {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .react-datepicker__time-list-item {
      height: unset !important;
      padding: 2px 7px !important;
      text-transform: lowercase;
      margin: 9px 5px !important;
      @include inter-normal(12, 800);

      &--selected {
        @include inter-normal(12, 800);
        border-radius: 0.4em;
        background-color: $color-syte-blue !important;
      }
    }

    .react-datepicker__header {
      background-color: white !important;
      border-bottom: none;
    }

    .react-datepicker__navigation {
      width: 25px;
      height: 25px;
      border-left-color: unset;
      border-right-color: unset;
      background: $color-syte-blue;
      border-radius: 4px;
      top: 5px;
      color: $color-syte-blue;
      text-indent: unset;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      &--previous {
        left: 5px;
      }

      &--next {
        right: 90px;
      }
    }

    .react-datepicker__navigation:hover {
      opacity: 1;
    }

    .react-datepicker__day-names {
      @include inter-normal(11, 900);
      line-height: 13.2px;
      background-color: $color-white;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      @include inter-normal(12, 800);

      border-bottom: 1px solid $color-gray_disable;
      padding-bottom: 0.55em;
    }

    .react-datepicker__navigation:focus {
      outline: none;
    }

    .react-datepicker__navigation-icon::before {
      content: unset;
    }

    .react-datepicker__navigation--previous::after {
      right: 7px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      @include reactDatePickerNavigation();
    }

    .react-datepicker__navigation--next::after {
      left: 7px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      @include reactDatePickerNavigation();
    }

    .react-datepicker__month {
      margin: unset;
    }

    .react-datepicker__time-container {
      border-left: none;
      width: unset;

      .react-datepicker__header--time {
        padding-bottom: unset;
      }
      .react-datepicker__time .react-datepicker__time-box {
        width: unset;
      }
    }

    .react-datepicker__header--time {
      padding-left: unset !important;
      padding-right: unset !important;
    }

    .react-datepicker__month-container {
      @include dashedDivider($side: right, $color: $color-gray_disable);
    }
  }
}
