@import '../../../styles/mixins';
@import '../../../styles/variables';

$max-cols: 5;
$tile-width: 170px;

@mixin media-cols() {
  @for $i from 1 through $max-cols {
    @media ('min-width: #{$i * 250px}') {
      grid-template-columns: repeat($i, $tile-width);
    }
  }
}

.moderation-modal {
  max-height: 788px;
  height: calc(100vh - 60px);

  @include modal-container();
  .modal-header {
    @include modal-header();
    flex-shrink: 0;
    border: none;
    display: flex;
    justify-content: space-between;
    .disconnect {
      color: $color-syte-negative;
      cursor: pointer;
    }
  }
  .modal-body {
    @include modal-body;
    flex: 1 1 auto;
    margin: 0 4px;
    overflow-y: auto;
    padding-left: 36px;
    padding-right: 28px;
    min-height: 170px;
    .tiles {
      display: grid;
      grid-gap: 40px 48px;
      @include media-cols();
    }
  }
  .modal-footer {
    @include modal-footer();
  }
}
