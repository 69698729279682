@import "../../../styles/mixins";
@import "../../../../styles/variables";


.disconnect-confirmation-modal {
  max-width: 480px;
  height: fit-content;
  margin: auto;
  align-items: flex-start;
  @include modal-container();
  .modal-header {
    @include modal-header();
    flex-shrink: 0;
    border: none;
  }
  .modal-body {
    @include modal-body;
    margin-bottom:40px;
  }
  .modal-footer {
    @include modal-footer();
    width: 100%;
    .disconnect-btn{
      background-color: $color-syte-negative;
    }
    .disconnect-btn.syte-button-disabled{
      background-color: #FBEBED;
    }
  }
}
