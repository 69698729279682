@import "src/styles/variables";

.edit-posts-page {
  .syte-page {
    &-content {
      padding-top: 56px;
    }
    &-title {
      flex: 1;
    }
  }
  .button-section {
    display: flex;
    align-items: center;
  }
}
.edit-posts-content {
  min-height: 364px;
  width: 540px;
  border-radius: 16px;
  border: 1px solid #DCDCDC;
  .no-posts {
    display: flex;
    flex-direction: column;
  }
  .no-posts, .change-order {
    padding: 56px 40px;
  }
  .color-black {
    color: $color-syte-black;
  }
  .color-grey {
    color: $color-syte-grey-10;
  }
}
