@import '../styles/main';

.syte-toggle {
  margin-top: 10px;
  justify-content: left;

  .syte-toggle-title {
    font-weight: normal;
  }

  .shop-settings-toggle-feature {
    font-weight: bold;
  }
}

.use-knn-container {
  padding: 10px 0 20px 0;
}

.shop-permissions-products {
  margin-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  .syte-section-box-header {
    height: 60px;
  }
}

.shop-permissions-features {
  flex-direction: column;
  padding-bottom: 20px;
  .syte-section-box-header {
    height: 60px;
  }
}
