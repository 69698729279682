@import '../../styles/main.scss';

.syte-experiments-list {
  min-width: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;

  .no-experiments {
    margin-top: 3em;
    text-align: center;
    width: 100%;
    color: $color-gray_disable;
    font-weight: 600;
  }

  .syte-experiments-list-accordion-item {
    border: $border;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .experiment-card-variant-table {
    border-top: $border;
    .syte-table-body {
      padding-bottom: 0;
    }
  }
}
