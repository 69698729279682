@import '../styles/main.scss';

.back-button-title {
  &-container {
    display: flex;
    align-items: center;
    color: $color-syte-black;
    gap: 4px;
  }
}
