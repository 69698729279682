ul > li > .ellipsis-with-tooltip {
  max-width: 32ch;
}

.MuiAutocomplete-popper {
  z-index: 1900 !important;
}

.disabled-item {
  display: flex !important;
  justify-content: space-between !important;
  color: #afafaf;

  .disabled-text {
    font-size: 10px;
  }
}


.visual-editor-modal {
  .custom-image-container {
    margin-top: 8px;
  }
  .custom-image-textbox {
    .input-base-wrapper {
      flex-direction: row-reverse;
    }
    .remove-image-url {
      cursor: pointer;
    }
    .syte-text-box-content {
      display: flex;
      align-items: center;
      margin: 0;
    }
    svg > * {
      stroke: #1E1E1E;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #ebebeb;
  }

  ::-webkit-scrollbar-track {
    background: rgba(235, 235, 235, 0.5);
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

.visual-editor-modal.preview-draft {
  top: 32px!important;
}

.visual-editor-modal{
  .syte-modal-content {
    display: flex;
    flex-direction: row;
    color: #1e1e1e;
    position: absolute;
    max-width: unset;
    border-radius: 0;
    inset: 0;
  }
}

.merchandising-rules-form-page {
  background: #fff;
}

