@import '../styles/main';

$item-padding: 16px;

.syte-tabs {
  a {
    color: unset;

    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      color: $color-syte-violet;
    }
    .syte-tabs-link-label {
      line-height: 24px !important;
    }
  }

  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid $color-gray_disable;
  height: 45px;
  &-item {
    display: inline-flex;
    justify-content: center;
    align-items: self-start;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    color: $color-grey-16;
    padding: 0 $item-padding 23px $item-padding;
    &-active {
      font-weight: 600;
      color: $color-syte-violet;
    }
  }
  .syte-tabs-active-tab {
    .syte-tabs-active-line {
      position: absolute;
      width: 100%;
      bottom: 0;
      background-color: $color-syte-violet;
      border: solid 2px $color-syte-violet;
      border-radius: 2px;
    }
  }
}
