.recently-viewed-settings-page {
  padding-bottom: 50px;
}
.recently-viewed-settings-container-wrapper {
  width: 100%;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 15px;
  margin-top: 20px;
  .out-of-stock-toggle-wrapper,
  .previously-purchased-toggle-wrapper {
    padding: 1.5rem;
    .out-of-stock-toggle-title,
    .previously-purchased-toggle-title {
      font-style: normal;
      font-weight: 400;
    }
  }
  .out-of-stock-toggle-wrapper {
    border-top: 1px dashed #dedede;
  }
  .syte-numeric-input {
    max-width: 100px;
  }
  .recently-viewed-limit,
  .recently-viewed-days {
    align-items: center;
    display: flex;
    padding: 10px 0 20px 0;
    .recently-viewed-limit-title,
    .recently-viewed-days-title {
      padding: 0 1.5rem;
    }
  }
}
