@import '../styles/main';

$spacing: 16px;

@mixin all-controls() {
  .syte-text-box,
  .syte-list-selector,
  .syte-radio-group,
  .syte-drop-down,
  .syte-checkbox {
    @content;
  }
}
.with-tooltiped-label {
  .syte-list-selector {
    padding: 0;
  }
  @include all-controls {
    box-shadow: none;
    border-radius: unset;
    &,
    * {
      font-weight: normal;
    }
    .syte-list-selector-popup-wrapper,
    .syte-input-base {
      width: 320px;
    }
    .syte-input-base {
      height: 38px;
    }
  }
  &.error,
  .error {
    .syte-input-base {
      border-color: $error-color;
    }
    .syte-error-label {
      margin: $spacing 0;
    }
  }
  > .label-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: $spacing;
    .syte-popup-trigger {
      margin-inline-start: 5px;
      svg,
      svg * {
        fill: $color-main_gray;
      }
    }
  }
}
