@import '../../../styles/main.scss';

.ui-template-card-cell {
  align-items: center;
  &-title {
    color: $color-main_gray;
    text-transform: uppercase;
  }
  &-value {
    line-height: 22px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    white-space: pre;
  }
}
