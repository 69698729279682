.number-of-range-input{
  width: 50%;
}

.minimal-range-input {
  padding-top: 0 !important;

  .syte-numeric-input {
    width: 25%;
  }
}
