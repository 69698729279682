@import '../styles/main';

.syte-tooltip {
  z-index: 9999;
  .syte-popup-content {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    border-radius: 8px;
    background: $background-color-6;
    color: $color-white;
    padding: 5px 6px;
  }
  &:before {
    background: $background-color-6 !important;
  }
}
