@import '../styles/main';

.syte-drop-down {
  &-input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &-input {
      width: 100%;
      user-select: none;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 5px;
    }

    &.error {
      @include inputBorderError();
    }

    &.borderless {
      background-color: unset;
      border: 1px solid transparent;
      text-transform: uppercase;
      color: $color-syte-blue;

      &:hover {
        border: 1px solid transparent !important;
      }
    }
  }

  &-popup-arrow {
    background-color: $color-syte-blue;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin-right: 5px;

    &-icon {
      transform: rotate(-90deg);
      width: 100%;
      height: 100%;
      padding: 1px;
      cursor: pointer;
      min-width: 24px;

      transition: 0.3s transform;

      & > path {
        stroke: $color-white;
      }

      &.opened {
        transform: rotate(90deg);
      }
    }
  }

  &-popup {
    @include unstyled-list($item-spacing: 0);
    position: absolute;
    background-color: $color-white;
    box-shadow: $box-shadow-main;
    border-radius: 8px;
    overflow: auto;
    z-index: 99999;
    // scrollbar-width: none;

    &-item {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px;
      color: $color-black-text;

      &-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.selected {
        background-color: $color-gray_disable;
        font-weight: 600;
      }

      &.disabled {
        color: $color-gray_disable;
      }

      &:not(&.disabled):hover {
        cursor: pointer;
        background-color: $color-gray_disable;
      }
    }
  }

  &.disabled {
    .syte-drop-down {
      &-input-wrapper {
        cursor: unset;
        &-input {
          color: $text-color-placeholder;
        }
      }

      &-popup-arrow {
        background-color: $text-color-disabled;

        &-icon {
          cursor: unset;
        }
      }
    }
  }
}

.syte-drop-down-input-tooltip {
  margin-bottom: 19px !important;
}

.ellipsis-with-tooltip-popup {
  &.syte-drop-down-input-tooltip,
  &.syte-drop-down-option-tooltip {
    max-width: 300px !important;
  }
}
