@import '../../styles/main';

$diffs-border: 1px solid $border-color;

.syte-diffs-card {
  border-top: $diffs-border;
  border-left: $diffs-border;
  border-right: $diffs-border;
  border-radius: 8px;

  &.syte-diffs-card-expanded {
    border-bottom: $diffs-border;
    .syte-status-card {
      --status-card-border-bottom-radius: none;
    }
  }

  .syte-diffs-card-header {
    border-bottom: $diffs-border;
    .syte-status-card-content {
      display: flex;
      align-items: center;
      padding-right: 45px;
    }
  }

  .syte-diffs-card-title {
    margin: auto 0 auto 16px;
    flex: 1;
  }

  .syte-diffs-card-content {
    display: flex;
    padding: 5px 44px 10px 44px;
  }

  .syte-diffs-card-changes-table {
    flex: 1;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    .syte-diffs-card-changes-table-header {
      color: $text-color-2;
      text-align: start;
      white-space: nowrap;
    }

    .syte-diffs-card-changes-table-row {
      border-top: 1px dashed $border-color;
    }

    .syte-diffs-card-changes-table-cell {
      line-height: 22px !important;
      padding: 7px 7px;
      width: 33%;
      word-wrap: break-word;

      &-new-value {
        color: $syte-primary-color;
        strong {
          font-weight: 700 !important;
        }
      }

      &-name {
        position: relative;

        .syte-system-change-indication-icon {
          position: absolute;
          transform: translate(-27px, -0.02em);
        }
      }
    }
    .syte-diffs-card-changes-table-cell-changed-by {
      text-align: right;
    }
  }
}

.syte-changed-by-tooltip {
  display: inline-block;
  width: unset !important;
  max-width: unset !important;
  .syte-changed-by-tooltip-text {
    color: $text-color-2;
    .syte-changed-by-tooltip-highlighted-text {
      color: $text-color-inverted;
    }
  }
}
