@import '../../../styles/main';

$syte-variant-panel-padding: 32px;

.syte-variant-panel {
  display: grid;
  grid-template-rows: fit-content(1em) fit-content(1em) 1fr;
  height: 100%;
  padding-top: $syte-variant-panel-padding;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 15px;
    padding: 0 $syte-variant-panel-padding;
  }

  &-sub-title {
    color: $color-main_gray;
    padding: 0 $syte-variant-panel-padding;
  }

  .syte-variant-panel-features-accordion {
    overflow: auto;
    padding: 0 $syte-variant-panel-padding;
    margin-top: 24px;

    .syte-variant-panel-feature-item-header {
      display: flex;
      align-items: center;
      border-top: $border;
      border-bottom: $border;
      .syte-variant-panel-feature-item-header-title {
        height: 4em;
        display: inline-flex;
        align-items: center;

        & > div {
          display: block;
          align-items: baseline;
        }

        &-changes {
          color: $color-main_gray;
          margin-left: 15px;

          &.active {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
        &-revert {
          line-height: 1px !important;
        }
      }
    }

    .syte-variant-panel-content {
      overflow: hidden;
    }

    .syte-variant-panel-item-content {
      padding-top: 16px;
      > *:first-child {
        width: 100%;
      }

      @media (max-width: 1440px) {
        .merchandising-rules-form-page {
          .syte-page-header,
          form > .syte-card {
            width: 100%;
          }
        }
      }
    }

    .feature-buttons-container {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > * + * {
        margin-left: 5px;
      }
    }
    .syte-collapse-collapsed {
      .feature-buttons-container {
        display: none;
      }
    }
    .syte-collapse:not(.syte-collapse-collapsed) {
      .syte-variant-panel-item-trigger {
        transform: rotate(90deg);
      }
    }

    .variant-panel-merchandising-rules {
      .syte-page-content {
        padding-bottom: 20px;
      }
    }
  }
  .variant-feature-reset-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
}
