.syte-overlay-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1900 /* same as semantic ui popup to match with tooltips etc*/;
}
