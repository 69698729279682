@import '../styles/main.scss';

$syte-header-background-color: $color-white;
$sye-header-height: 78px;

.syte-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $syte-header-background-color;
}
