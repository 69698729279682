@import '../../styles/main.scss';

.ui-template-card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 72px;
  background-color: $color-background;
  border-radius: 8px;
  border: none;
  transition: none !important;
  margin-top: 1rem;
  &:hover {
    background-color: $color-background-hover;
  }

  &-content {
    flex: 1;
    width: 100%;
    display: grid;
    grid-template-columns:
      minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
      86px min-content;
    padding: 17px 24px;
  }
}
