@import '../styles/stories-common';

$svg: calc(1.75 * 16px);

.add-post {
  cursor: pointer;
  height: $story-height;
  width: $story-width;
  border-radius: $story-radius;
  padding: $story-padding;
  background: #fff;
  border: 2px solid #1353F7;
  .bg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: $color-syte-grey-70;
    border-radius: inherit;
  }
  svg {
    height: $svg;
    width: $svg;
    path {
      fill: $color-syte-primary;
      stroke: $color-syte-primary;
    }
  }
}