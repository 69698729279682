@import '../../styles/main.scss';
@import '../experiment-styles.scss';

$input-spacing: 40px;
$input-width: 326px;

.syte-experiment-form {
  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &-name {
      margin-right: $input-spacing;
      min-width: $input-width;
    }

    &-metric-wrapper {
      &-dropdown {
        margin-right: $input-spacing;
        min-width: $input-width;
        margin-bottom: 17px;
      }

      &-sub-title {
        color: $color-main_gray;
      }
    }

    &-actions {
      align-self: center;
      margin-left: 20px;
    }

    &-status {
      white-space: nowrap;
      align-self: center;

      .placeholder {
        color: $color-main_gray;
      }

      .experiment-status-badge {
        margin-left: 5px;
      }
    }
  }

  .syte-variants-table {
    margin-top: 40px;
  }
}
