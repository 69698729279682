@import "../../../styles/mixins";

.delete-confirmation-modal {
  min-width: 480px;
  @include modal-container();
  .modal-header {
    @include modal-header();
    flex-shrink: 0;
    border: none;
  }
  .modal-body {
    @include modal-body;
    margin-bottom:40px;
  }
  .modal-footer {
    @include modal-footer();
  }
}
