@import '../styles/main';

$syte-list-selected-item-background: $background-color-5;
$syte-list-selected-item-card-shadow: $box-shadow-2;

.syte-list {
  @include unstyled-list();
  position: relative;
  margin: 0;
  padding: 0 12px;
  max-height: 100%;
  overflow: auto;

  &-no-results {
    @include flexCenterAll();
    flex-flow: column;
    padding-top: 4%;
  }

  &-item {
    width: 100%;
    margin: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
