@import '../styles/main.scss';

.syte-experiments-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;

  .syte-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &-content {
      flex-grow: 1;
    }
  }
  &-title {
    line-height: 90px !important;
  }
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &-buttons > :last-child {
      margin-left: 10px;
    }
  }
}
