@import '../styles/main';

.syte-loader {
  @include flexCenterAll();
  background-color: $color-background;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;

  opacity: 1;
  transition: 0.4s opacity;

  .icon-wrapper {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
    }

    &-content {
      width: 90px;
    }
  }

  &.is-closing {
    opacity: 0;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
