@import '../styles/main';

$syte-grouped-list-selected-item-background: $background-color-5;
$syte-grouped-list-selected-item-card-shadow: $box-shadow-2;

.syte-grouped-list {
  @include unstyled-list();
  position: relative;
  margin: 0;
  padding: 0;
  max-height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  &-no-results {
    @include flexCenterAll();
    flex-flow: column;
    padding-top: 4%;
  }

  &-item {
    width: 100%;
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-selected {
      background-color: $syte-grouped-list-selected-item-background;
      position: sticky;
      top: 0;
      bottom: 0;
      z-index: 10;
      .syte-card-item {
        box-shadow: $syte-grouped-list-selected-item-card-shadow;
      }
    }
  }
  .group {
    padding: 0;
  }
}
