@import '../styles/main';
$transition-duration: 0.1s;

@mixin transitionProperties() {
  visibility: visible;
  position: relative;
  transition: visibility $transition-duration duration ease-in-out,
    position $transition-duration ease-in-out;
  will-change: transition;
}

.syte-app-header {
  grid-area: header;
  flex-wrap: wrap;
  .publish-dropdown {
    width: 326px;
    margin-right: 32px;
  }

  .syte-app-header-section {
    display: flex;
    align-items: center;

    &-indicator {
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 24px;
    }

    &-left {
      display: flex;
      align-items: center;
      height: 80px;
      padding-left: 24px;

      &-logo {
        display: flex;
        padding: 16px;
        @include flexCenterAll();
        @include transitionProperties();

        .syte-side-navigation-logo {
          padding: 12px 4px;
          display: flex;
          cursor: pointer;
          align-items: center;
          height: 48px;

          img {
            width: 40px;
            height: 24px;
          }
        }

        .arrow-button {
          @include flexCenterAll(true);
          height: 100%;
          opacity: 1;
          color: #878787;

          &-icon {
            &:hover {
              border: 1px solid $color-gray_disable;
              border-radius: 3px;
            }
          }

          &-divider {
            height: 32px;
            width: 2px;
            border-radius: 2px;
            background-color: $color-gray_disable;
            margin: 0 8px;
          }
        }

        .account-info {
          display: flex;
          flex-direction: column;
          padding-left: 8px;
          @include transitionProperties();

          &-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 150px;
            color: $color-syte-grey-10;
          }

          &-action-text {
            color: $color-syte-blue;
            cursor: pointer;
          }
        }

        &-back {
          margin-right: 8px;
        }
      }
    }

    &-right {
      height: 80px;
      padding-right: 24px;
      display: flex;
      align-items: center;

      .preview-button {
        width: 127px;
        border-radius: 4px;
        height: 32px;
        margin-right: 24px !important;
        padding: 8px 12px;
        background-color: $color-grey-21;
      }
    }
  }

  .versioning-icon {
    margin-right: 35px;
    width: 35px;
    @include flexCenterAll();
    position: relative;

    &-selected {
      path {
        fill: $color-syte-violet;
      }

      .versioning-icon-selected-underline {
        position: absolute;
        width: 100%;
        bottom: -27px;
        left: 0px;
        background-color: $color-syte-violet;
        border: solid 2px $color-syte-violet;
        border-radius: 2px;
      }
    }
  }

  &-divider {
    height: 32px;
    width: 2px;
    border-radius: 2px;
    background-color: $color-gray_disable;
    margin: 0 24px 0 0;
  }
}
