@import '../styles/main';

.syte-nav-tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 105px;
  overflow-y: visible;
  overflow-x: hidden;
  list-style-type: none;

  &:hover {
    overflow-x: auto !important;
  }
}

.syte-nav-tabs-tab {
  position: relative;
  height: 95px;
  padding: 0 10px;
  margin: 0 10px 0 0;
  min-width: 100px;
  overflow-y: visible;

  a {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    color: $text-color-disabled;
    font-weight: 600;
    letter-spacing: 2px;
  }

  &:hover {
    background-color: $color-dark-3;
    a {
      color: $text-color;
    }
  }
}

.syte-nav-tabs-tab-selected {
  a {
    color: $text-color;
  }

  .syte-nav-tab-highlight {
    background-color: $color-green;
    height: 5px;
    border-radius: 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
  }
}
