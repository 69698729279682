@import '../styles/main';

$textual-field-width: 25em;
$numeric-field-width: 5em;

$header-height: 60px;
$form-field-margin: 10px 0;

.syte-filter-set {
  .syte-section-box-header.syte-section-box-header-divider {
    height: 60px;
  }
  .syte-section-box {
    display: block;
    margin-top: 24px;
    .set-name {
      width: 50%;
      margin: $form-field-margin;
      .syte-text-box {
        .syte-input-base {
          width: $textual-field-width;
        }
      }
    }
    .display-settings {
      display: flex;
      > div {
        width: 50%;
        > * {
          margin: $form-field-margin;
          .syte-numeric-input {
            width: $textual-field-width;
          }
        }
      }
      .toggle-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        & > div {
          margin-top: 12px;
        }

        .syte-toggle {
          .syte-toggle-titles-wrapper > .syte-toggle-title {
            font-weight: normal;
          }
        }
      }
      .auto-expand-section {
        .syte-drop-down {
          width: $textual-field-width;
        }
        .syte-numeric-input-content{
          width: $textual-field-width;
        }
      }
    }
  }
}
