.visual-editor-content-text {

  &-default {
    width: 280px;
    text-align: center;
  }

  &-no-results {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: auto;
    &-suggestions {
      color: #878787;
    }
  }
}
