@import '../../../styles/main';

$input-min-width: 90px;
$input-width: 326px;

.syte-accounts-list-section {
  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;

    .sort-input {
      min-width: $input-min-width;
      width: $input-width;
      max-width: $input-width;
      padding-right: 10px;
    }

    .include-archived-input {
      white-space: nowrap;
      padding-right: 10px;

      label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .search-input {
      margin-left: auto;
      min-width: $input-min-width;
      width: $input-width;
      max-width: $input-width;
    }
  }

  .account-list {
    padding: 0 7px;
  }
}
