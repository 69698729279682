@import '../../styles/mixins.scss';
@import '../../styles/variables';

@mixin modal-container() {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0px -1px 6px -2px rgba(0, 0, 0, 0.06);
}

@mixin modal-header() {
  padding: 16px 40px;
  border-bottom: 1px solid $color-syte-grey-70;
  color: $color-syte-black;
}

@mixin modal-body() {
  padding: 16px 40px;
  color: $color-syte-black;
}

@mixin modal-footer() {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: 24px 40px;
  border-top: 1px solid $color-syte-grey-70;
  .syte-button {
    margin-left: 4px;
    border-radius: 4px;
    padding: 12px 16px;
    border: none;
    &-ghost {
      color: $color-syte-black;
    }
    &-disabled {
      background-color: $color-syte-grey-70;
      color: $color-syte-grey-50;
    }
    &-primary {
      background-color: $color-syte-primary;
    }
  }
}
