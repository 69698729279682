.warning-indication-icon {
  opacity: 0;
  width: 0;
  transition: 0.2s opacity, 0.5s width;

  &.show {
    opacity: 1;
    width: 100%;
    min-width: 24px;
  }
}
