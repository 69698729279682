@import '../styles/main';

.syte-list-action-bar {
  &__controls {
    display: flex;
    justify-content: flex-start;
    .syte-drop-down {
      width: 30%;
      margin-right: 15px;
    }
    .syte-search-input {
      width: 30%;
      margin-right: 15px;
    }
    .syte-button-secondary {
      margin-left: auto;
    }
  }
}