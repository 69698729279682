.syte-modal-backdrop {
  background: #1e1e1e;
  opacity: 0.6;
}
.info-modal {
  .syte-modal-content {
    max-height: 80%;
    overflow-y: auto;
    margin: 24px 40px;
    max-width: 80%;
    text-align: center;
  }
}

.syte-info-modal-header {
  display: grid;
  padding: 24px 40px;
  text-align: initial;
  .syte-info-modal-title,
  .syte-info-modal-description {
    padding-bottom: 32px;
  }
}
.syte-info-modal-content {
  padding: 0 40px;
  display: contents;
}
.syte-info-modal-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #dedede;
  position: sticky;
  bottom: -0.1%;
  background: #fff;
  .syte-info-modal-button {
    float: right;
    margin: 24px 40px;
    bottom: 0;
  }
}
