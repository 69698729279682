@import '../styles/main.scss';

.syte-notification {
  position: relative;
  border-radius: 7px;
  box-shadow: $box-shadow;
  background: $background-color;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  min-height: 64px;
  animation: fadein 400ms;
  transition: opacity 400ms ease-in-out, height 400ms ease-in-out;

  &-hidden {
    opacity: 0;
    height: 0px;
  }

  &-closeable {
    .syte-notification-content {
      padding-right: 25px;
    }
  }

  &:hover {
    border-color: $border-color-highlighted;
  }

  .syte-notification-icon {
    margin-left: 24px;
    position: relative;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .syte-basic-icon {
      position: relative;
      z-index: 2;
    }

    &-bg {
      position: absolute;
      z-index: 1;
      opacity: 0.2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &-content {
    flex-grow: 1;
    line-height: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 5px;
    margin-right: 5px;
    width: 100%;
    overflow: hidden;

    &-typography {
      line-height: 22px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      white-space: normal;
      display: -webkit-box !important;
      word-wrap: break-word;
    }
  }

  &-close {
    @include flexCenterAll();
    position: absolute;
    z-index: 3;
    right: 16px;
    border-radius: 4px;
    top: 16px;

    &:hover {
      background-color: $color-dark-gray;
    }
  }

  &-error {
    background-color: #fcedec;
  }

  &-success {
    background-color: #eefbee;
  }
  &-warning {
    background-color: #f5e8ca;
  }
  &-info {
    background-color: $color-experiments-color-4;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
