.rec-engines-general-settings {
  margin-bottom: 30px;
}

.rec-engines-configuration-settings {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 15px;
  margin-top: 20px;
}

.add-to-cart-form {
  .form-select-container {
    max-width: 210px;
  }
}

