@import '../../styles/main.scss';

.syte-review-changes-dialog {
  .syte-modal-backdrop {
    background-color: $color-dark-gray;
    opacity: 0.3;
  }
  .syte-modal-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    max-width: 70vw;
    height: 90vh;
    padding: 0 20px !important;
    top: 5vh;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 20px 0;
    width: 100%;
    border-bottom: 1px solid $border-color-disabled;

    &-title {
      @include flexCenterAll();
      flex-direction: column;

      .first-row {
        align-self: flex-start;
        color: $text-color;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      .experiments-notification {
        align-self: flex-start;

        .syte-popup-trigger {
          vertical-align: middle;
        }
      }
    }

    &-icon {
      align-self: baseline;
    }
  }

  &-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    padding-bottom: 16px;
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    min-height: 81px;
    margin-top: auto;

    .cancel-button {
      margin-right: 13px;
    }
  }
}
