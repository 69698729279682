.syte-experiment-list-section {
  height: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;

    .status-dropdown {
      min-width: 250px;
    }

    .search-input {
      min-width: 250px;
    }
  }
}
