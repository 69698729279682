@import '../styles/main.scss';

.syte-social-discovery-page {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;

  .syte-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &-content {
      flex-grow: 1;
    }
  }
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-link {
      display: flex;
      align-items: center;
      cursor: pointer;

      &-icon {
        path {
          stroke: $color-black-text;
        }
      }
    }
  }
}
