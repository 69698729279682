@import '../styles/main.scss';

.syte-team-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &-save-button {
    padding-left: 16px;
  }

  .syte-team-container-edit-header {
    margin-left: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .syte-page-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .syte-basic-icon {
        border: 1px solid $color-main_gray;
        border-radius: 4px;
        cursor: pointer;
        transition: opacity 200ms;
        margin-right: 10px;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    .syte-user-list {
      min-width: 250px;
      margin-right: 30px;
      max-height: 70vh;
      // max-width: 350px;
    }
    .syte-user-form {
      flex-grow: 1;
    }
  }
}
