.syte-toast-manager {
  position: absolute;
  width: 520px;
  bottom: 64px;
  left: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 15px 0 0;
  pointer-events: none;
  z-index: 999999;
  & > * {
    pointer-events: auto;
  }
}
