@import '../styles/stories-common';

.story-container {
  cursor: pointer;
  display: inline-block;
  position: relative;
  .action-icon {
    display: none;
    position: absolute;
    z-index: 2;
    top: -2px;
    right: -6px;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.08), 0px 2px 8px -2px rgba(0, 0, 0, 0.08);
  }
  &:hover {
    .action-icon {
      display: flex;
      &:hover {
        border: 1px solid $color-syte-negative
      }
    }
  }
}

.story-container {
  background: #fff;
  border: 2px solid $color-grey-8;
  border-radius: 50%;
  padding: 2px;
  width: $story-width;
  height: $story-height;
  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    pointer-events: none;
  }
  .avatar-image {
    object-fit: cover;
    user-select: none;
    object-position: center;
  }
  &.selected {
    border: 2px solid #a2a2a2;
  }
}
