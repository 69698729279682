@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.ig-container {
  margin: 82px auto;
  padding: 56px;
  background: #fff;
  max-width: 645px;
  border-radius: 16px;
  border: 1px solid $color-syte-grey-70;
  box-shadow: 0 8px 24px -4px rgba(0, 0, 0, 0.08), 0px 6px 12px -6px rgba(0, 0, 0, 0.12);
  .text-muted {
    color: $color-syte-grey-10!important;
  }
  .text-link {
    color: $color-syte-primary!important;
  }
  svg {
    margin-right: 4px;
  }
  img {
    width: 100%;
    margin-bottom: 32px;
  }
  h1 {
    margin-bottom: 8px;
  }
  .feature-description {
    max-width: 390px;
  }
  .stepper {
    position: relative;
    margin-top: 32px;
    .step, .card, a {
      @include flexCenterVertical();
    }
    .step {
      margin-top: 24px;
      .card {
        justify-content: space-between;
        flex-grow: 1;
        margin-left: 16px;
        padding: 12px 16px;
        background: $color-syte-grey-80;
        border-radius: 8px;
      }
      .content {
        display: flex;
        flex-direction: column;
        & > span:nth-child(2) {
          margin-top: 2px;
        }
      }
    }
    .circle {
      position: relative;
      z-index: 2;
      width: 32px;
      height: 32px;
      @include flexCenterAll();
      background: $color-syte-grey-70;
      border-radius: 50%;
    }
  }
}