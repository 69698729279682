.ml-auto {
  margin-left: auto !important;
}

.d-block {
  display: block;
}

.invisible {
  visibility: hidden;
}
