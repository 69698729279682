@import '../styles/main';
@import '../styles/variables.scss';

.syte-page {
  padding: 0px 32px 0px 24px;

  & > .syte-page-header {
    border-bottom: $border;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .syte-page-title {
      line-height: 4.35em !important;
      height: 4.35em;
    }
    .syte-page-sub-title {
      margin-top: -30px;
      width: 100%;
      color: $color-syte-grey-20;
    }
  }
  & > .syte-page-content {
    padding-top: 24px;
  }
}
.syte-page-clean {
  padding-left: 56px !important;
  padding-top: 56px !important;
  background-color: #f8f8f8;
  min-height: 100%;

  & > .syte-page-header {
    border-bottom: none !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .syte-page-title {
      line-height: 6px !important;
      height: 6px;
    }
    .syte-page-sub-title {
      margin-top: 4px;
      width: 100%;
      color: $color-syte-grey-20;
    }
  }
  & > .syte-page-content {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.syte-page-centered {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: min-content 1fr;
  justify-content: center;
  padding-left: unset;
  padding-right: unset;
  background: #f8f8f8;
  overflow-y: auto;

  & > .syte-page-header {
    border-bottom: unset;
  }

  & > .syte-page-header,
  & > .syte-page-content {
    width: 1077px;
  }

  & > .syte-page-content {
    height: 100%;
    overflow: auto;
  }
}
