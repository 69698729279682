@import '../styles/main.scss';

.syte-toast {
  width: 100%;
  margin-bottom: 10px;
  opacity: 1;
  transition: opacity 400ms ease-in-out, height 400ms ease-in-out;

  &-remove {
    opacity: 0;
  }
}
