@import '../styles/main';

.ui.top.center.visible.popup.syte-tooltip.syte-popup {
  &.display-type-preview__popup {
    width: 222px;
    height: 210px;
    .syte-popup-content {
      background-color: $color-white;
      box-shadow: $box-shadow-main;
      display: flex;
    }
    &:before {
      background-color: $color-white !important;
      box-shadow: none;
    }
    * {
      width: 100%;
      height: 100%;
    }
  }
}
