@import '../../../styles/main';

.multiple-rec-per-page-settings {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 15px;
  margin-top: 20px;
  .multiple-rec-per-page-settings-title {
    line-height: 24px !important;
    text-transform: uppercase;
  }
  .multiple-rec-per-page-settings-text.syte-toggle-title {
    line-height: 23px !important;
  }
  .multiple-rec-per-page-settings-enabler {
    margin-top: 24px;
    padding-top: 26px;
    padding-bottom: 7px;
    border-top: 1px solid #dedede;
    display: flex;
  }
  .duplication-drag-and-drop-tooltip {
    display: flex;
    padding: 18px 0;
    .duplication-drag-and-drop-tooltip-title {
      padding-bottom: 5px;
    }
    .duplication-drag-and-drop-tooltip-title.disabled {
      color: #979797;
    }
  }

  .drag-and-drop-list {
    padding-left: 15px;
  }
  .drag-and-drop-list.disabled {
    color: #979797;
  }
}
.drag-and-drop-list-item {
  visibility: visible !important;
}
li.drag-and-drop-list-item {
  padding-bottom: 7px;
  margin-bottom: 12px;
  padding-left: 10px;
  border-bottom: 1px dashed #dedede;
  list-style-image: url('../../../../assets/icons/d&d-list-item.svg');
  z-index: 99 !important;

  .drag-and-drop-list-item-value {
    margin-left: 12px;
  }
}
