.personalization-settings {
  margin-bottom: 70px;
  &-form {
    width: 100%;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 15px;
    margin-top: 20px;
    .form {
      &-title {
        line-height: 24px!important;
        text-transform: uppercase;
      }
      &-select-sm {
        max-width: 20%;
        width: 100%;
        margin-top: 10px;
      }
    }
    .divider {
      margin-top: 24px;
      border-top: 1px solid #dedede;
    }
    .flex-item {
      display: flex;
      padding: 20px 0;
      &.separator {
        border-top: 1px dashed #dedede;
      }
      &.row {
        flex-direction: row;
        align-items: center;
      }
      &.column {
        flex-direction: column;
      }
      &.h-gap {
        gap: 0 64px;
      }
    }
  }
  .fallback {
    margin-top: 30px;
  }
}
