@import '../styles/global-styles.scss';
@import '../components-dummy/styles/main.scss';

.appContent {
  height: 100vh;
  display: grid;
  grid-template-areas:
    'header header'
    'sideNav mainContent';
  grid-template-columns: minmax($side-nav-width-minimized, max-content) 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: 0;
  background: $color-grey-21;
}
.contentContainer {
  overflow: auto;
  grid-area: mainContent;
  position: relative;
  .syte-page {
    height: 100%;
    overflow: auto;
  }
}
.contentContainer.open {
  left: 535px;
}
.contentContainer.collections,
.contentContainer.collection-rules,
.contentContainer.new {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: rgb(248, 248, 248);
}

.contentContainer.ui-templates {
  display: flex;
  justify-content: center;
}
