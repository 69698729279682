@import '../styles/main.scss';

.syte-nav-button {
  border: 1px solid $color-main_gray;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -1px;

  &-disabled {
    border: 1px solid $color-gray_disable;

    &:hover {
      border: 1px solid $color-gray_disable !important;
    }
  }

  &:hover {
    border: 1px solid $color-dark-gray;
  }
}
