.shopper-experiences-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .header-button-group {
    display: flex;
    flex-direction: row;
    gap: 8px;
    .settings-dropdown {
      width: 170px;
    }
  }
}
