@import '../styles/main.scss';

.syte-shop-list {
  .syte-shop-empty-results {
    margin-top: 130px;
    margin-bottom: 50px;
  }
  .shop-list-filter-options {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
    > div {
      flex-basis: 25%;
    }
  }
}
