@import '../styles/main';

$solid-border: 1px solid $border-color;
$spacing: 16px;

button.syte-button-secondary {
  color: $syte-primary-color;
  border-color: $syte-primary-color;
}

.syte-filters-list {
  > * {
    margin-top: 2em;
  }
  &__header {
    display: flex;
    > * {
      width: 50%;
    }
    .with-tooltiped-label {
      padding-inline-start: 0;
      .order-method-field p {
        display: block;
      }
    }
    border-bottom: $solid-border;
    .deeptags-additional-settings > * {
      margin: 1em 0;
    }
  }
  &__controls {
    .syte-drop-down {
      width: 320px;
    }
  }
  &__title {
    display: flex;
    align-items: flex-end;
    h1 {
      text-transform: uppercase;
      margin-inline-end: 4px;
      line-height: 24px !important;
    }
    .syte-basic-icon {
      fill: #f3b61f;
      path {
        fill: #fff;
      }
      path:first-of-type {
        fill: #f3b61f;
      }
    }
  }
  .syte-filters-list__title {
    text-transform: uppercase;
    margin-inline-end: 4px;
    line-height: 24px !important;
  }
  p.filters-list-counter {
    color: $color-grey-16;
    font-weight: normal;
  }
  .syte-filter-card {
    margin-bottom: $spacing;
  }
}
.ui.popup.transition.visible.syte-popup.syte-tooltip.order-method-field__tooltip {
  max-width: unset;
  div.syte-popup-content {
    display: block;
  }
}
