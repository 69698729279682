@import '../styles/main';

$backdrop-transition-time: 0.3s;
$content-transition-time: $backdrop-transition-time + 0.2s;

.syte-side-panel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  &-content {
    padding: 32px;
    position: absolute;
    z-index: 999;
    height: 100%;
    width: 45vw;
    background-color: $color-white;
    transform: translateX(100%);
    transition: $content-transition-time transform;
    will-change: transform;
  }

  &-backdrop {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: $color-dark-gray;
    opacity: 0;
    transition: $backdrop-transition-time opacity;
    will-change: opacity;
  }

  &.opened {
    .syte-side-panel {
      &-backdrop {
        opacity: 0.1;
      }

      &-content {
        transform: translateX(0);
      }
    }
  }

  &.wide {
    .syte-side-panel-content {
      width: calc(100% - 300px);
    }
  }

  .syte-side-panel-close-icon {
    position: absolute;
    top: 25px;
    right: 35px;
  }
}
