@import '../styles/main';

$solid-border: 1px solid $border-color;
$spacing: 16px;

@mixin card-header-font() {
  text-transform: unset;
}
// CARD HEADER
.syte-filter-card {
  &.hidden {
    display: none;
  }
  .syte-card-item .syte-entity-card-content {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 20em auto 1fr;
  }
  .syte-entity-card-status {
    &.Visible {
      background-color: $color-syte-blue;
    }
    &.Hidden {
      background-color: $color-main_gray;
    }
    &.New {
      background-color: $color-syte-orange;
    }
  }
  .syte-entity-card-cell {
    &.display-type {
      display: flex;
      @include card-header-font();
      .syte-entity-card-cell-label {
        margin-inline-end: 0.5em;
      }
    }
    &.display-name {
      width: 15em;
      display: flex;
      @include card-header-font();
      margin-inline-start: $spacing;
    }
    &.dragging-handle {
      svg {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        &:first-of-type {
          margin-inline-end: -15px;
        }
      }
      &.active svg {
        opacity: 1;
      }
    }
    &.filter-item-quick-actions,
    &.submit-form-filter {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > div.syte-popup-trigger {
        display: flex;
        svg {
          margin-inline-start: 1em;
          height: 2em;
          &:not(.hidden-card-tooltip) {
            &,
            * {
              fill: $color-black-text;
            }
          }
        }
      }
    }
  }
}

// CARD CONTENT - FORM
.syte-filter-card {
  .syte-collapse-header[aria-expanded='true'] ~ .syte-collapse-content {
    height: fit-content !important;
    border: $solid-border;
    border-top: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: $spacing;
    cursor: initial;
    .syte-collapse-content-inner-wrapper {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - #{$spacing}));
      column-gap: calc(#{$spacing} * 2);
      grid-template-rows: 10em 10em 10em;
      grid-template-areas:
        'left-column display-type'
        'left-column display-type'
        'left-column .';
      .left-column {
        grid-area: left-column;
      }
      .display-type-section {
        grid-area: display-type;
        max-width: 300px;
      }
      .with-tooltiped-label {
        padding: $spacing;
      }
      .display-type-preview {
        display: flex;

      }
    }
  }
}

.syte-list-selector-popup.data-source-selector {
  width: 340px !important;
  transform: translateY(8px);
  z-index: 10;
}
