@import '../styles/main';

$item-spacing: 20px;

.syte-user-form {
  width: 100%;
  // max-width: 326px;
  min-width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  &-last-name {
    margin: 10px 0;
  }

  &-change-password {
    padding: 10px 0;
    cursor: pointer;
    text-decoration: underline;
    color: $color-syte-blue;
  }

  .syte-text-box,
  .syte-drop-down {
    width: 326px;
  }

  &-role {
    width: 100%;
    padding-top: $item-spacing;
  }
}
