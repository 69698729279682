@import '../styles/main';

$syte-popup-menu-trigger-color: $syte-primary-color;
$syte-popup-menu-background-color: $background-color-6;
$syte-popup-menu-text-color: $color-black-text;
$syte-popup-menu-item-border-color: $color-gray_disable;

.syte-popup-menu::before {
  box-shadow: -1px -1px 0 0 $color-background !important;
  background-color: $color-background !important;
}

.syte-popup-menu-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.syte-popup-menu-trigger {
  @include flexCenterAll($inline: true);
  color: $syte-popup-menu-trigger-color;
  display: flex;
  align-items: center;
}

.syte-popup-menu-content {
  @include unstyled-list();
  box-shadow: $box-shadow-main;
  background-color: $color-background;
  border-radius: 10px;
}

.syte-popup-menu-item {
  color: $syte-popup-menu-text-color;
  text-align: center;
  padding: $popup-menu-item-padding;
  cursor: pointer;

  & + & {
    border-top: 1px solid $syte-popup-menu-item-border-color;
  }
}

.syte-popup-menu-item-text {
  margin-right: 14px;
}
