@import '../styles/main';

.syte-popup {
  &.ui.popup {
    padding: unset;
    font-weight: unset;
    font-style: unset;
    background: unset;
    border: unset;
    color: unset;
    border-radius: 12px;
    box-shadow: unset;
    line-height: unset;

    &.syte-popup-fit-content {
      max-width: unset !important;
    }
  }
}

.syte-popup.hide-popup-content-arrow::before {
  display: none;
}

.syte-popup-trigger {
  display: inline-flex;
}
