@import './variables.scss';
@import './mixins.scss';

.syte-field {
  margin-bottom: 16px;
}

.syte-field-label {
  margin-bottom: 8px;
  font-size: 12px;
  color: $color-black-text-label;
  display: block;
}

.syte-title {
  text-transform: uppercase;
  color: $text-color;
}

h1.syte-title {
  font-size: 15px;
}

.syte-row {
  display: inline-flex;
}

.syte-column {
  flex: 1;
}
.inputFontSize16 {
  font-size: 16px;
}
.inputFontSize14 {
  font-size: 14px;
}
.syte-dynamic-input-base {
  display: flex;
  align-items: center;
  border-radius: $input-border-radius;
  color: $text-color;
  background-color: $input-background-color;
  height: $input-height;
  position: relative;
  padding-left: 15px;
  padding-right: 3px;
  padding-top: 0;
  padding-bottom: 0;
  @include inputBorder();
  input {
    font-weight: normal;
    flex: 1;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    border: unset !important;
    color: $text-color;
    overflow: hidden;
    background-color: transparent;
    &::placeholder {
      color: $text-color-placeholder;
    }
    &:disabled {
      background-color: transparent;
    }
  }
  &:hover,
  &:focus,
  &:focus-within {
    @include inputBorderHighlight(true);
  }
  &:focus {
    color: unset;
  }
  &:focus-within {
    background-color: $color-white;
  }
  &.disabled {
    border-color: $border-color-disabled !important;
    opacity: 1;
    & > input {
      color: $text-color-disabled;
    }
    pointer-events: auto;
  }

  &.error > input {
    background-color: unset;
    color: unset;
  }

  &.error {
    @include inputBorderError;
    &:hover {
      @include inputBorderError;
    }
  }
}

.syte-input-base {
  display: flex;
  align-items: center;
  border-radius: $input-border-radius;
  color: $text-color;
  background-color: $input-background-color;
  height: $input-height;
  position: relative;
  padding-left: 15px;
  padding-right: 3px;
  padding-top: 0;
  padding-bottom: 0;
  @include inputBorder();
  input {
    font-size: 14px;
    font-weight: normal;
    flex: 1;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    border: unset !important;
    color: $text-color;
    overflow: hidden;
    background-color: transparent;
    &::placeholder {
      color: $text-color-placeholder;
    }
    &:disabled {
      background-color: transparent;
    }
  }
  &:hover,
  &:focus,
  &:focus-within {
    @include inputBorderHighlight(true);
  }
  &:focus {
    color: unset;
  }
  &:focus-within {
    background-color: $color-white;
  }
  &.disabled {
    border-color: $border-color-disabled !important;
    opacity: 1;
    & > input {
      color: $text-color-disabled;
    }
    pointer-events: auto;
  }

  &.error > input {
    background-color: unset;
    color: unset;
  }

  &.error {
    @include inputBorderError;
    &:hover {
      @include inputBorderError;
    }
  }
}
