@import '../../styles/main.scss';

.syte-published-status-indication {
  margin-right: 25px;

  .syte-notification-content-typography {
    display: flex !important;
    min-width: 230px;
    user-select: none;
    align-items: center;
  }

  .syte-basic-icon {
    width: 24px;
    height: 100%;
  }

  .syte-popup-trigger {
    margin-left: auto;

    .syte-published-status-indication-popup-icon {
      &-wrapper {
        border-radius: 4px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-content: center;
      }
    }
  }

  &.hidden {
    opacity: 0;
  }

  &-popup-dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;

    .syte-published-status-indication-popup-dialog-review-button {
      margin-top: 10px;
      width: 100%;
    }

    &:hover {
      border-color: transparent;
    }
  }
}

.syte-published-status-indication-popup-dialog {
  transform: translate(12px, 10px) !important;
}
