@import '../../../styles/main';

.syte-account {
  min-height: 112px;

  &-card {
    padding: 20px 5px 23px 20px;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
  }

  &-profile-icon {
    margin-right: 12px;
    min-width: 35px;
  }

  &-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-right: 45px;
    width: 100%;

    &-name {
      color: $color-black-text;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      margin: unset;
    }

    &-status {
      text-transform: capitalize;
      margin-left: 5px;
    }

    &-meta {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $color-dark-gray;
      white-space: nowrap;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-align: end;
      padding-top: 4px;

      &-id {
        padding-right: 6px;
      }

      &-icon {
        max-width: 15px;
        max-height: 15px;
        padding-right: 3px;
      }
    }
  }
}
