.syte-controlled-content-toggle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &-content {
    padding: 5px 0 5px 12px;

    &.hidden {
      opacity: 0;
    }
  }
}
