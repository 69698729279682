@import '../../../styles/main.scss';

.syte-user-list {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 100%;
  overflow-x: visible;
  margin-left: -5px;

  &-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
    overflow-x: visible;

    .syte-list {
      padding: 0 5px;
    }

    .syte-user-list-empty-results {
      margin-top: 130px;
      margin-bottom: 50px;
      height: 100%;
    }
  }
  &-search {
    padding: 0 5px;
  }
}
