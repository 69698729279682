.shop-the-look-container {
  .shop-the-look-buttons-section {
    display: flex;
    & > * + * {
      margin-left: 5px;
    }
  }
  .syte-page-title {
    flex: 1;
  }
}
