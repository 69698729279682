@import '../../../styles/main';

.open-new-tab-settings {
  padding-bottom: 10px;
  .results-display-title {
    line-height: 24px !important;
    text-transform: uppercase;
  }
  .mobile-toggle,
  .desktop-toggle {
    display: flex;
  }
  .toggles-title {
    margin-bottom: 13px;
    margin-right: 10px;
    display: flex;
    margin-top: 24px;
    padding-top: 26px;
    border-top: 1px solid #dedede;
  }
  .open-new-tab-toggles {
    display: flex;

    .desktop-new-tab-toggle {
      margin-left: 30px;
    }
  }
}
