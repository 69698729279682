@import '../../../styles/main';

.override-selesctors-settings {
  margin-top: 20px;
  display: flex;
  .override-settings-text.syte-toggle-title {
    line-height: 23px !important;
  }
  .override-settings-text.disabled {
    color: #979797;
  }
  .override-settings-tooltip {
    margin-left: 5px;
  }
}

.override-settings-textboxes {
  display: flex;
  margin-top: 20px;
  .syte-text-box {
    width: 100%;
  }
  .syte-text-box.disabled {
    .syte-field-label {
      color: #dedede;
    }
  }
  .syte-input-base.required {
    border-color: #e4572e;
  }
  .syte-input-base {
    max-width: 325px;
    height: 40px;
  }
  .image-url-selector-input {
    margin-right: 30px;
  }
}
