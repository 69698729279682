@import '../styles/main';

.syte-list-section {
  width: 471px;
  height: 100%;
  display: flex;
  flex-direction: column;
  &-header {
    display: flex;
    border-bottom: $border;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    &.title-only {
      display: flex;
      align-items: center;
      height: 80px;
      text-transform: uppercase;
    }
  }
  &-body {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    .syte-grouped-list {
      .group-header:nth-child(1) {
        :first-child {
          border-top: unset;
        }
      }
    }
  }
}
