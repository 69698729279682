.shop-the-look-settings-container-wrapper {
  .shop-the-look-general-settings,
  .shop-the-look-results-display-settings {
    width: 100%;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 15px;
    margin-top: 20px;
  }
  .results-display-title,
  .results-sorting-title {
    line-height: 24px !important;
    text-transform: uppercase;
  }

  .shop-the-look-results-to-display {
    display: flex;
    padding: 10px 0 15px 0;
    margin-top: 24px;
    padding-top: 26px;
    border-top: 1px solid #dedede;
    .results-to-display-title {
      padding-top: 10px;
    }
    .results-to-display-input-wrapper {
      margin-left: 10%;
      display: grid;
      .results-to-display-input {
        width: max-content;
      }
      .results-to-display-input-error {
        color: #e4572e;
        justify-self: end;
      }
    }
  }
  .shop-the-look-fallback-method {
    border-top: 1px dashed #dedede;
    padding: 15px 0 10px 0;
    .fallback-method-title {
      display: flex;
      .fallback-method-title-tooltip {
        margin-left: 5px;
        margin-top: -10px;
      }
    }
    .fallback-method-dropdown {
      margin-top: 10px;
      width: 25%;
    }
  }
  .shop-the-look-include-main-product {
    padding: 10px 0;
    border-bottom: 1px dashed #dedede;
    display: flex;
    margin-top: 24px;
    padding-top: 26px;
    border-top: 1px solid #dedede;
    .include-main-product-text {
      line-height: 32px !important;
    }
  }

  .include-main-product-tooltip {
    margin-left: 5px;
  }

  .shop-the-look-model-version {
    padding-top: 15px;
    .fallback-method-title {
      line-height: 23px !important;
    }
  }

  .shop-the-look-shuffle-results {
    padding-top: 15px;
    .shuffle-results-text {
      line-height: 32px !important;
    }
  }
}
