@import './../../../styles/variables';

.posts-bar {
  width: auto;
  overflow: auto hidden;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-syte-grey-70;
  padding-left: 18px;
  height: 105px;
  .add-post {
    flex-shrink: 0;
  }
  .add-post,
  .syte-nav-tabs > .syte-nav-tabs-tab {
    margin-right: 6px;
  }
  .syte-nav-tabs {
    padding-left: 0;
    margin: 0;
  }
}
