.syte-settings-page-content {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.syte-page-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 80px;
  border-bottom: $border;
  font-weight: 900;
}

.syte-page-section {
  border: $border;
  border-radius: 10px;
}
