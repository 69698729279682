@function is-important($important) {
  @return #{if($important, '!important', '')};
}

@mixin ellipsisTextOverflow() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin flexCenterAll($inline: false) {
  display: #{if($inline, inline-flex, flex)};
  justify-content: center;
  align-items: center;
}

@mixin flexCenterVertical($inline: false) {
  display: #{if($inline, inline-flex, flex)};
  align-items: center;
}

@mixin flexCenterHorizontal($inline: false) {
  display: #{if($inline, inline-flex, flex)};
  justify-content: center;
}
@mixin dashedLineSVG($color, $orientation: vertical) {
  @if $orientation == vertical {
    background-image: url("data:image/svg+xml, <svg width='1px' height='3px' xmlns='http://www.w3.org/2000/svg'><rect width='1px' height='1px' fill='transparent' /><rect width='1px' height='1px' fill='%23dedede' /><rect width='1px' height='1px' fill='transparent' /></svg>");
    width: 1px;
    height: 100%;
  } @else {
    background-image: url("data:image/svg+xml, <svg width='3px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='1px' height='1px' fill='transparent' /><rect width='1px' height='1px' fill='%23dedede' /><rect width='1px' height='1px' fill='transparent' /></svg>");
    width: 100%;
    height: 1px;
  }
  background-repeat: repeat;
}

@mixin dashedDivider($side: bottom, $color: #979797) {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    @if $side == bottom {
      @include dashedLineSVG($color, horizontal);
      left: 0;
      bottom: 0;
    } @else if $side == top {
      @include dashedLineSVG($color, horizontal);
      left: 0;
      top: 0;
    } @else if $side == right {
      @include dashedLineSVG($color);
      top: 0;
      right: 0;
    } @else if $side == left {
      @include dashedLineSVG($color);
      top: 0;
      left: 0;
    }
  }
}

@mixin hideScrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin unstyled-list($item-spacing: 0px) {
  list-style-type: none;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;

  li:not(:first-child) {
    margin-top: $item-spacing;
  }
}
