@import '../styles/main';

.syte-role-based-side-nav {
  transition: 0.5s transform, 0.2s min-width;
  will-change: transition;

  &:not(.selected) {
    transform: translateX(-100%);
  }

  .syte-side-navigation-back-button-minimized {
    margin-bottom: 20px;
  }

  .syte-shop-selector {
    max-width: 358px;
  }
}
