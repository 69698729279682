@import '../styles/main.scss';

$syte-dialog-background-color: $background-color-7;

.syte-dialog {
  background-color: $syte-dialog-background-color;

  .syte-dialog-close-button {
    position: absolute;
    right: -20px;
    top: -20px;
    .syte-button-primary-icon {
      width: 18px;
    }
  }

  .syte-dialog-content {
    color: $text-color-inverted;
    position: relative;
    padding: 20px;
    text-align: center;
    line-height: 1.5;
    .syte-button-secondary {
      color: $color-white;
      border-color: $color-white;
      &:hover {
        color: $color-black-text;
        background-color: $color-white;
      }
    }
  }

  .syte-dialog-body {
    margin: 34px 0;
  }

  .syte-dialog-actions {
    display: flex;
    justify-content: center;
    > * + * {
      margin-left: 17px;
    }
  }
}
