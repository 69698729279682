@import '../../styles/main';

.syte-changes-preview {
  width: 100%;
  .syte-feature-group {
    & + .syte-feature-group {
      margin-top: 42px;
    }

    .syte-diffs-card + .syte-diffs-card {
      margin-top: 17px;
    }
  }

  &-select-all {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px 0;
    width: 100%;

    &-checkbox {
      margin-top: 8px;
    }
  }
}
