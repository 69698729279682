.bought-together-settings {
  margin-bottom: 70px;
  &-container-wrapper {
    width: 100%;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 15px;
    margin-top: 20px;
    .display-title {
      line-height: 24px !important;
      text-transform: uppercase;
    }
    .bought-together-results-to-display {
      display: flex;
      padding: 10px 0 20px 0;
      margin-top: 24px;
      padding-top: 26px;
      border-top: 1px solid #dedede;
      .results-to-display-title {
        padding-top: 10px;
      }
      .results-to-display-input-wrapper {
        margin-left: 10%;
        display: grid;
        .results-to-display-input {
          width: max-content;
        }
        .results-to-display-input-error {
          color: #e4572e;
          justify-self: end;
        }
      }
    }
    .bought-together-fallback-method {
      border-bottom: 1px dashed #dedede;
      padding: 20px 0;
      padding-top: 26px;
      margin-top: 24px;
      border-top: 1px solid #dedede;

      .fallback-method-dropdown {
        margin-top: 10px;
        width: 20%;
      }
    }
    .bought-together-model-version {
      padding-top: 20px;
      max-width: 300px;
    }
  }
  .fallback {
    margin-top: 30px;
  }
}
