.bought-together-container {
  .bought-together-buttons-section {
    display: flex;
    & > * + * {
      margin-left: 5px;
    }
  }
  .syte-page-title {
    flex: 1;
  }
}
