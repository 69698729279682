@import '../../../../components-dummy/styles/main';

.story-thumbnail-nav {
  padding: 0 1px;
  min-width: auto;
  & > .syte-nav-tab-highlight {
    display: none;
  }
  &.syte-nav-tabs-tab {
    height: 87px;
    &:after {
      width: 100%;
      display: inline-block;
      position: absolute;
      background: $color-syte-grey-60;
      height: 4px;
      left: 0;
    }
    &.syte-nav-tabs-tab-selected:after {
      background: $color-syte-primary;
    }
    &.syte-nav-tabs-tab-selected:after, &:hover:after {
      content: '';
    }
    &:hover {
      background: #fff;
    }
  }
}
