.results-modal-settings {
  .syte-page-header {
    display: grid;
    grid-template-areas: 'title submit' 'tabs tabs';
    flex: 0 1 auto;
    .syte-tabs {
      grid-area: tabs;
    }
  }
  &-submit-button {
    grid-area: submit;
  }
}