.syte-page.collections,
.syte-page-content.collections {
  display: flex;
  flex-direction: column;
}
.syte-page > .syte-page-header {
  border-bottom: unset;
}
.syte-page > .syte-page-content {
  padding-top: unset;
}
