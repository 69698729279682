.experiment-status-color {
  &-created {
    background-color: $color-syte-orange;
  }
  &-running {
    background-color: $color-syte-blue;
  }
  &-ended {
    background-color: $color-main_gray;
  }
}
