@import '../styles/main.scss';

$open-close-button-rounded-corner-size-sm: 150px;
$open-close-button-rounded-corner-size-lg: $open-close-button-rounded-corner-size-sm + 50px;
$header-left-and-right-padding: 30px;
$transition-duration: 0.1s;

@mixin headerPadding($left-and-right) {
  padding: 0 $left-and-right 35px;
}

@mixin hiddenAbsolute() {
  position: absolute;
  visibility: hidden;
}

@mixin transitionProperties() {
  visibility: visible;
  position: relative;
  transition: visibility $transition-duration duration ease-in-out,
    position $transition-duration ease-in-out;
  will-change: transition;
}

.syte-side-navigation {
  position: relative;
  background-color: $color-white;
  grid-area: sideNav;

  z-index: 2;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: $side-nav-width;
  overflow: hidden;

  &-items {
    padding: 0px 30px;
    flex-grow: 1;
    max-height: 100%;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: #EBEBEB $color-white;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #EBEBEB;
      border: 2px solid $color-white; 
    }
    
    &::-webkit-scrollbar-track{
      background-color: $color-white;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      flex-flow: column;

      &-group {
        margin-top: 8px;
        &-name {
          font-weight: 600;
          font-size: 12px;
          padding: 8px 0;
        }
        &-shop-settings {
          margin-top: auto;
          padding-top: 28px;
        }
      }

      .syte-side-navigation-item {
        .side-navigation-sub-items {
          @include unstyled-list($item-spacing: 10px);
          padding: 0;
          margin: 0 0 0 50px;
        }
        .syte-side-navigation-link {
          padding: 10px 0;
          margin: 5px 0;
          display: flex;
          align-items: center;
          position: relative;
          color: $text-color;
          flex: 1;
          cursor: pointer;

          .syte-basic-icon {
            width: 20px;
            margin: 0 15px;
          }

          &.syte-side-navigation-selected-link {
            color: $color-syte-primary;
            background-color: $color-syte-primary-90;
            border-radius: 4px;
          }

          & .syte-popup-trigger {
            display: flex;
            align-items: center;
          }
        }
        &-title {
          @include transitionProperties();
        }
      }
    }
  }

  .side-navigation-sub-items {
    .syte-side-navigation-selected-link {
      &:after {
        content: none !important;
      }
    }
  }

  .open-close-button {
    position: absolute;
    width: 28px;
    height: 106px;
    top: 50%;
    right: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &-rounded-corner {
      transform: rotate(45deg);
      position: absolute;
      z-index: -1;
      background-color: $color-background;

      &.sm {
        border-radius: 26px;
        width: $open-close-button-rounded-corner-size-sm;
        height: $open-close-button-rounded-corner-size-sm;
        left: 26px;
      }

      &.lg {
        width: $open-close-button-rounded-corner-size-lg;
        height: $open-close-button-rounded-corner-size-lg;
        border-radius: 50%;
        left: 19px;
      }
    }

    .arrow-button-icon {
      position: absolute;
      left: 2px;
    }
  }

  .arrow-button-icon {
    cursor: pointer;
    transition: transform 0.2s;

    path {
      stroke: $color-black-text;
    }

    &.minimized {
      transform: scale(-1, 1);
    }
  }

  &.minimized {
    min-width: $side-nav-width-minimized;

    .side-navigation-sub-items {
      display: none;
    }

    .syte-collapse-icon {
      display: none;
    }

    .syte-side-navigation-header {
      flex-direction: column;
      @include headerPadding($header-left-and-right-padding - 10px);

      .arrow-button-divider {
        @include hiddenAbsolute();
      }
    }
    .shopBox {
      @include hiddenAbsolute();
    }
    .syte-side-navigation-item {
      .syte-side-navigation-link {
        align-items: center;
        justify-content: center;
      }

      .syte-basic-icon {
        margin: unset;
      }

      &-title {
        @include hiddenAbsolute();
      }
    }
  }

  .syte-side-navigation-item-skeleton {
    display: flex;
    height: 54px;
    align-items: center;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
