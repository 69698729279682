.product-tag {
  &:hover {
    .sku {
      display: flex;
    }
  }

  &.not-indexed {
    .sku {
      display: flex;
      border: none;
    }
  }
}
