@import '../styles/main';

$shadow-size: 12px;
$border-radius: 8px;
$general-padding: 16px;

.syte-list-selector {
  background-color: $color-white;

  padding: 0 $general-padding $general-padding $general-padding;
  border-radius: $border-radius;

  cursor: pointer;

  .syte-input-base:hover {
    border: none !important;
  }

  .syte-input-base {
    background: #f2f2f2;
  }

  &-selected-item {
    &-bar {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border: none !important;
    }

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      min-height: 48px;
      overflow: hidden;
      padding-right: 10px;
    }
  }

  &-popup {
    overflow-y: hidden;
    position: absolute;
    max-height: 400px;
    padding: 12px;
    transform: translateY(-$border-radius);
    z-index: 3;

    &-arrow {
      background-color: $color-syte-blue;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      margin-right: 5px;

      &-icon {
        transform: rotate(-90deg);
        width: 100%;
        height: 100%;
        padding: 1px;
        cursor: pointer;
        min-width: 24px;

        transition: 0.3s transform;

        & > path {
          stroke: $color-white;
        }

        &.opened {
          transform: rotate(90deg);
        }
      }
    }

    &-wrapper {
      background-color: $color-white;
      box-shadow: $box-shadow-main;
      border-radius: 8px;
      margin-top: -($shadow-size + $border-radius);
      padding-top: $border-radius;
    }

    &-search {
      padding: 0 $general-padding;
      margin: 10px 0 10px 0;
    }

    &-list {
      @include unstyled-list($item-spacing: 0);
      overflow: auto;
      max-height: 244px;
      height: 100%;
      padding-bottom: $general-padding;
    }

    &-item {
      padding: 10px $general-padding;
      cursor: pointer;
      overflow: hidden;

      &:hover,
      &.selected {
        background-color: $color-gray_disable;
      }
    }
  }
}
