@import '../../styles/main.scss';

.syte-create-experiment-form {
  &-popup-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: $color-white;
    box-shadow: $box-shadow-main;
    padding: 25px;

    &-name-input {
      min-width: 300px;
      margin-bottom: 24px;
    }
  }
}
