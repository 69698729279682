@import '../../../components-bl/styles/main';
@import '../../../components-dummy/styles/mixins';

.ranking-table-wrapper {
  .title {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .no-data-fields {
      color: $color-syte-violet;
      margin-right: 1.3em;
    }

    .add-new-row-button {
      margin-right: 10px;
    }
  }
  .syte-table {
    &-body {
      overflow: unset;

      .tr {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 15px 0;
      }

      .td {
        min-height: unset;
      }
    }
    .no-rows {
      text-align: center;
      padding: 20px;
    }

    .name-cell {
      @include flexCenterAll;

      width: 100%;

      .syte-popup-trigger {
        align-items: center;
        margin-left: 5px;
      }
    }
    .syte-table-editable-cell-error {
      margin-top: 32px;
    }

    .syte-table-editable-cell {
      &.disabled {
        border: unset !important;

        .dropdown,
        .syte-text-box-content {
          background-color: unset;
          border: unset !important;
          opacity: 0.5;

          input,
          .text {
            color: $color-black-text;
          }
        }
      }

      .dropdown {
        width: 100%;
      }
    }

    .enabled-checkbox {
      width: 0px;
      background-image: unset;
      margin-right: 26px;
    }

    & .td {
      justify-content: flex-start;
      padding: 0;
      padding-right: 10px;
    }
    & .th {
      padding: 0 18px 0 4px;
    }

    .syte-table-body::-webkit-scrollbar {
      display: block;
    }
    .syte-table-header .th:not(.delete-column):not(.no-divider):after,
    .syte-table .td:not(.delete-column):not(.no-divider):after {
      background-image: unset;
    }
  }

  .ranking-strategy-table-loader {
    text-align: center;
    margin-top: 30px;
  }
}
