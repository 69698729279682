@import '../styles/main';

.syte-date-time-range-picker-toggle {
  .syte-date-time-range-picker-toggle-edit-button {
    color: $color-syte-blue;
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
  }

  .syte-date-time-range-picker-toggle-values-container {
    margin-left: 55px;
    font-size: 13px;
    font-weight: bold;
  }
}
