@import '../styles/main.scss';

.syte-stories-page {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;

  .syte-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &-content {
      flex-grow: 1;
    }
  }
}
