@import '../../../styles/main.scss';

.syte-create-account-form {
  &-popup-content {
    @include flexCenterAll();
    flex-direction: column;

    padding: 20px;

    &:hover {
      border-color: transparent;
    }
  }

  &-text-input {
    padding-bottom: 20px;
  }
}
