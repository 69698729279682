@import '../../styles/main';

.empty-collections {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70vw;
  height: 60vh;
  padding: 32px 40px;
  padding-top: 50px;
  border: 1px $color-syte-grey-80 solid;
  margin: auto;
  background-color: $color-syte-white;
  box-shadow: 0px 2px 8px -2px #00000014;
  border-radius: 8px;
  gap: 32px;
  img {
    width: 55%;
    height: 50vh;
  }
}

.create-collection {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .create-collection-text {
    color: $color-syte-grey-10;
  }
  .create-collection-button-container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px;
    gap: 8px;
  }
}
