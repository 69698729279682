.ranking-container {
  .ranking-container-buttons-section {
    display: flex;
    & > * + * {
      margin-left: 5px;
    }
  }

  .syte-page-title {
    flex: 1;
    .ranking-container-title {
      line-height: 90px !important;
    }
  }
}
