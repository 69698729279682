@import '../../../styles/main.scss';

.syte-user-list-empty-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-title {
    font-weight: 800;
    color: $color-black-text;
    margin-bottom: 25px;
  }
  &-text {
    color: $color-main_gray;
    margin-bottom: 35px;
    text-align: center;
  }
}
