.collections-main {
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
	.collections-sorting-and-filtering {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		border: 1px solid rgb(220, 220, 220);
		border-radius: 8px;
		background-color: #FFFFFF;
		.collections-search {
			display: flex;
			flex-flow: wrap;
			border-bottom: 1px solid rgb(242, 242, 242);
			-webkit-box-align: center;
			align-items: center;
			background-color: rgb(255, 255, 255);
			border-radius: 8px;
			padding: 6px 12px;
			.syte-search-input {
				margin: 6px 5px 6px 0;
				max-width: 176px;
			}
			.input-base-wrapper {
				height: 32px;
			}
			.syte-text-box-content {
				align-self: center;
			}
		}
	}
}
