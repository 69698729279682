// Colors Pallette - those will be used for all the site check in story book
$color-syte-primary: #0073ff;
$color-syte-primary-10: #004599;
$color-syte-primary-20: #005ccc;
$color-syte-primary-80: #cce3ff;
$color-syte-primary-90: #e5f1ff;

$color-syte-black: #1e1e1e;
$color-syte-grey-10: #6e6e71;
$color-syte-grey-20: #878787;
$color-syte-grey-30: #afafaf;
$color-syte-grey-50: #c5c5c5;
$color-syte-grey-60: #dcdcdc;
$color-syte-grey-70: #ebebeb;
$color-syte-grey-80: #f2f2f2;
$color-syte-grey-90: #f8f8f8;
$color-syte-white: #ffffff;

$color-syte-negative: #dc3545;
$color-syte-positive: #28a745;
$color-syte-warning: #f3b61f;

// Colors Pallette
// new color pallette need to change names later
$color-syte-blue: #85cdca;
$color-syte-blue-disabled: #c2e6e4;
$color-syte-blue-hover: #5faca9;
$color-syte-blue-login-bg: #93cecd;
$color-syte-orange: #e8ab7c;
$color-syte-violet: #762232;
$color-black-text: #404040;
$color-black-text-label: #1e1e1e;
$color-main_gray: hsl(0, 0%, 59%);
$color-gray_disable: #dedede;
$color-dark-gray: #5f5f5f;
$color-bg-gray-inputs: #fafafa;
$color-background: #f7f9ff;
$color-background-hover: rgba(234, 237, 247, 1);
$color-white: #ffff;
$color-experiments-color-1: #5438dc;
$color-experiments-color-2: #357ded;
$color-experiments-color-3: #b594b6;
$color-experiments-color-4: #dc3545;
$color-experiments-color-5: #f3b61f;
$color-experiments-color-6: #dc6acf;
$box-shadow-main: 0px 0px 12px rgba(0, 0, 0, 0.09);

// color pallette based on older figma - should be erased as we re-design the components
$color-green: #85cdca;
$color-green-2: rgba(133, 205, 202, 0.4);
$color-red: #762232;
$color-dark: #0d1a3a;
$color-dark-2: rgba(0, 0, 0, 0.09);
$color-dark-3: rgba(0, 0, 0, 0.03);
$color-grey: #c4c4c4;
$color-grey-2: #404040;
$color-grey-3: rgba(255, 255, 255, 0.2);
$color-grey-4: rgba(64, 64, 64, 0.8);
$color-grey-5: #979797;
$color-grey-6: rgba(64, 64, 64, 0.5);
$color-grey-7: rgba(222, 222, 222, 0.4);
$color-grey-8: #dedede;
$color-grey-9: #f5f5f5;
$color-grey-10: rgba(13, 26, 58, 0.2);
$color-grey-11: rgba(151, 151, 151, 0.4);
$color-grey-12: rgba(151, 151, 151, 0.2);
$color-grey-13: rgba(151, 151, 151, 0.8);
$color-grey-14: #f7f7f7;
$color-grey-15: rgba(13, 26, 58, 0.6);
$color-grey-16: rgba(64, 64, 64, 0.6);
$color-grey-17: rgba(64, 64, 64, 0.3);
$color-grey-18: rgba(64, 64, 64, 0.89);
$color-grey-19: rgba(151, 151, 151, 1);
$color-grey-20: #dededf;
$color-grey-21: #f8f8f8;
$color-white: #ffffff;
$color-white-2: #f6f6f6;
$color-orange: #e8ab7c;

// Functional Colors
$syte-primary-color: $color-syte-blue;
$syte-primary-color-disabled: $color-syte-blue-disabled;

$disabled-color: $color-grey;
$disabled-color-2: $color-grey-7;

$error-color: $color-experiments-color-4;

$text-color: $color-black-text;
$text-color-2: $color-main_gray;
$text-color-3: $color-grey-6;
$text-color-4: $color-grey-15;
$text-color-5: $color-grey-13;
$text-color-disabled: $disabled-color;
$text-color-inverted: $color-white;
$text-color-placeholder: $text-color-disabled;

$background-color: $color-white;
$background-color-2: $color-grey-9;
$background-color-3: $color-grey-4;
$background-color-4: $color-grey-11;
$background-color-5: $color-grey-14;
$background-color-6: $color-dark;
$background-color-7: $color-grey-18;
$background-color-8: $color-dark-3;
$background-color-disabled: $disabled-color-2;
$background-color-disabled-2: $color-grey-10;
$background-color-disabled-3: $color-grey;
$background-color-disabled-4: $color-grey-12;

$input-background-color: $color-bg-gray-inputs;
$input-disabled-background-color: $color-gray_disable;

$border-color-highlighted: $syte-primary-color;
$border-color: $color-gray_disable;
$border-color-2: $color-grey;
$border-color-3: $color-grey-3;
$border-color-4: $color-grey-5;
$border-color-5: $color-white;
$border-color-disabled: $border-color;

$border: 1px solid $border-color;

$input-border-color: $color-gray_disable;

$input-inline-button-background-color: $syte-primary-color;

$button-background-color-disabled: $background-color-disabled-2;

// Scalars
$input-height: 40px;
$button-height: 36px;

$input-border-radius: 8px;

$popup-menu-item-padding: 15px 23px;

// animation
$transition-standard: all 0.2s ease-in-out;

// shadows
$box-shadow: 0px 0px 12px $color-dark-2;
$box-shadow-2: 0px 0px 12px $color-dark-3;

/* Variables from old platform*/

// // old syte colours
// $syte-blue: #85CDCA;
// $syte-blue-active: rgb(102, 173, 255);
// $black-text: #404040;
// $default-text-color: rgba(191,191,191,.87);
// $syte-violet: #762232;

// // Shades of gray
// $padding-gray: #f7f7f7;
// $border-gray: #dedede;
// $border-gray-2: #dededf;
// $border-gray-3: #979797;
// $border-gray-4: rgba(245, 245, 245, 0.25);
// $gray-text-dark-gray: rgba($black-text, 0.6);;
// $gray-text-dark: rgba($black-text, 0.65);;
// $border-dark-gray: #dededf36;
// $border-dark-gray-2: #ffffff33;
// $gray-text-disabled: rgba($black-text, 0.4);
// $active-domain: rgba(85, 83, 83, 0.171);

// // Shades of white
// $label-white: #ffffff;
// $border-white:#f5f5f5;
// $border-white-2:#f6f6f6;

// // new syte colours
// $syte-brown: #762232;
// $syte-teal: #85CDCA;
// $syte-black: #272727;
// $syte-orange: #E8AB7C;

// // shadows:
// $gray-shadow-1: gba(34,36,38,.12);
// $gray-shadow-2: gba(34,36,38,.15);

// $black-shadow-weak: rgba(0, 0, 0, 0.03);
// $black-shadow: rgba(0, 0, 0, 0.09);
// $black-shadow-strong: rgba(0, 0, 0, 0.12);

// // other vars
// $transition-standard: all 0.2s ease-in-out;

// // layout sizes //
// $content-width: 1350px;
// $content-width-padded: 1260px;
// $content-height: calc(100vh - 100px - (45px * 2)); // page - header - top+bottom padding

// //input colors
// $input-inline-button-color: $black-text;
// $unset: unset;
// $none: none;
// $button-shadow: 0px 0px 20px $black-shadow;
// $button-shadow-hover: 0px 0px 9px $black-shadow-strong;
// $dropdown-item-hover-background-color: rgba($border-gray-3, 0.08);

// //errors
// $error-text-color: #9f3a38;
// $error-border-color: #752133;
