.start-edit-popup {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 24px 20px 24px 40px;
  color: #1E1E1E;
  background: #FFFFFF;
  border: 1px solid #C5C5C5;
  box-shadow: 0 12px 42px -4px rgba(0, 0, 0, 0.12), 0px 8px 18px -6px rgba(0, 0, 0, 0.12);
  border-radius: 16px;

  .start-edit-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h2, h3 {
    letter-spacing: -0.01em;
  }

  img {
    margin: 16px 0 48px 0;
  }

  span.start-edit-popup-store-url-text {
    display: block;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .start-edit-popup-store-url-container {
    width: 424px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .start-edit-popup-store-url-input {
    margin: 16px 0;
  }

  .start-edit-button {
    width: fit-content;
    align-self: center;
  }
}