.aug-search-filter-sets {
  &__list {
    list-style: none;
    &-item {
      .syte-card-item.syte-card-clickable {
        display: flex;
        .syte-entity-card-content {
          justify-content: flex-start;
          padding: 0 5em;
        }
      }
    }
  }
}
