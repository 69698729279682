@import '../styles/main';

.syte-intro-screen {
  opacity: 1;
  transition: 0.4s opacity;
  .syte-dialog-close-button {
    display: none;
  }
  &-body {
    &-list {
      @include unstyled-list($item-spacing: 13px);

      &-item {
        margin: 20px 0;

        &-title {
          margin-bottom: 10px;
        }
      }
    }

    &-link {
      text-decoration: underline !important;
    }

    .syte-intro-screen-unpublished-changes-notification {
      user-select: none;
      min-width: 215px;
      transition: unset;
      animation: unset;

      .syte-basic-icon {
        position: unset !important;
        transform: unset !important;
      }
    }
  }

  &.is-closing {
    opacity: 0;
  }
  .syte-dialog-content {
    background-color: $color-white;
    color: $color-black-text;
    padding: 30px 20px;
    max-width: 600px;
  }
  .syte-dialog-header {
    display: flex;
    justify-content: center;
    align-items: center;

    .syte-intro-header {
      max-width: 400px;
    }

    h1 {
      line-height: 4.35em !important;
      height: 4.35em;
      text-transform: uppercase;
      small {
        color: $color-main_gray;
        display: block;
        text-transform: none;
      }
    }
    .syte-intro-img {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(40%, -40%) rotate(35deg);
      width: 75px;
    }
  }

  .syte-dialog-body {
    text-align: left;
    margin: 0;
    line-height: 1.3;
    .syte-intro-screen-body-main-block {
      color: $color-main_gray;
      margin-bottom: 15px;
    }
    .syte-intro-screen-body {
      width: 80%;
      margin: auto;
      margin-bottom: 50px;
      .syte-intro-screen-body-list-item {
        margin: 0 0 25px;
        position: relative;
        .syte-basic-icon {
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(-120%, -3px);
        }
        .syte-intro-screen-body-list-item-title {
          margin-bottom: 0;
        }
        .syte-intro-screen-body-list-item-description {
          color: $color-main_gray;
          .desc-img-smaple {
            display: flex;
            align-items: center;
            span {
              line-height: 1.4 !important;
              padding-right: 5px;
            }
            img {
              height: 30px;
            }
          }
        }
      }
    }
  }
  .syte-dialog-footer {
    width: 80%;
    margin: auto;
    justify-content: space-between;
    align-items: baseline;
    a {
      color: $color-syte-blue;
    }
  }
}
