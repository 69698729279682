@import '../styles/main.scss';

.syte-shop-empty-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .syte-shop-empty-results-title {
    font-weight: 800;
    color: #0d1a3a; /* TODO use variables! */
    margin-bottom: 25px;
  }
  .syte-shop-empty-results-text {
    color: #737b7d; /* TODO use variables! */
    margin-bottom: 35px;
    text-align: center;
  }
}
