@import '../styles/main.scss';

$syte-shop-card-title-text-color: $text-color;
$syte-shop-card-subtitle-text-color: $text-color-2;
$syte-shop-card-archived-title-text-color: $text-color-4;
$syte-shop-card-archived-subtitle-text-color: $text-color-5;

.syte-shop-card {
  padding: 20px 5px 23px 20px;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  .syte-shop-card-wrapper {
    display: flex;
    .shop-card-content {
      flex-grow: 1;
      margin-left: 20px;
      .shop-card-details {
        display: flex;
        > div:first-child {
          margin-right: 15px;
        }
      }
    }
    .first-row {
      color: $syte-shop-card-title-text-color;
      display: flex;
      margin-bottom: 12px;
      .syte-badge {
        margin-left: 20px;
      }
    }

    .shopId,
    .shopCard-time {
      color: #979797;
    }
    .shopCard-time {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      img {
        margin-right: 5px;
      }
    }
    .last-update {
      margin-right: 3px;
    }

    h3 {
      margin-bottom: unset;
    }
  }

  .syte-popup-menu-trigger {
    opacity: 0;
  }

  &:hover {
    .syte-popup-menu-trigger {
      opacity: unset;
    }
  }

  &-archived {
    .syte-shop-card-wrapper {
      .first-row {
        color: $syte-shop-card-archived-title-text-color;
      }

      .second-row {
        color: $syte-shop-card-archived-subtitle-text-color;
      }
    }
  }
}
