.empty-ui-templates-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #1E1E1E;
  img {
    width: 60%;
  }
}

.start-edit-container {
  width: 35%;
  max-width: 378px;
  h2 {
    letter-spacing: -0.01em;
  }
  span.start-edit-text {
    display: block;
    margin: 16px 0;
    line-height: 22px;
  }
  button {
    width: 100%;
  }
}
