@import '../styles/main.scss';
@import '../styles/_components.scss';

.syte-dynamic-text-box {
  .syte-dynamic-text-box-content {
    display: flex;
    &:hover,
    &:focus,
    &:focus-within {
      .syte-dynamic-text-box-actions {
        visibility: unset;
      }
    }
    input:focus {
      flex: 1 !important;
    }
    input:not(:focus) {
      flex: unset !important;
    }
    .syte-dynamic-text-box-actions {
      @include flexCenterAll();
      visibility: hidden;
    }
  }

  &.syte-dynamic-text-box-mode-material {
    .syte-dynamic-text-box-content,
    .syte-dynamic-input-base:hover {
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
      background-color: $color-white;
      padding-left: 0;
    }
  }

  &.fill-width {
    width: 100%;
  }
}
