@import '../../styles/variables.scss';

.syte-experiments-form-container {
  &-header {
    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;

      .experiments-btn-dashboard {
        margin-right: 20px;
      }
    }

    .syte-page-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 20px;
    }

    &-back-button {
      margin-right: 10px;
    }
  }
}
.syte-experiments-form-container-header-buttons-action {
  margin-right: 10px;
}
